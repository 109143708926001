import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import SearchInput from 'components/atoms/inputs/SearchInput';

const SearchHeader = ({ value, onChange }) => {
    return (
        <Container>
            <SearchInput value={value} onChange={onChange} />
        </Container>
    );
};

SearchHeader.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default SearchHeader;
