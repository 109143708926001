import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { NotificationType } from 'types/data/notifications';
import Message from 'components/molecules/alerts/Message';
import NotificationCard from 'components/molecules/cards/NotificationCard';

const NotificationsList = ({ notifications, removeNotification }) => {
    if (!notifications || !notifications.length) {
        return <Message text="Todas as notificações já foram vistas" />;
    }

    return (
        <Container>
            {notifications.map((notification, index) => (
                <NotificationCard key={index} notification={notification} remove={removeNotification} />
            ))}
        </Container>
    );
};

NotificationsList.propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.shape(NotificationType).isRequired).isRequired,
    removeNotification: PropTypes.func.isRequired
};

export default NotificationsList;
