import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    > h3 {
        font-size: 1.1rem;
        padding-bottom: 0.5rem;
    }

    > main {
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-width: 100%;

        @media screen and (max-width: 340px) {
            .MuiFormControlLabel-label {
                font-size: 0.8rem;
            }
        }
    }
`;
