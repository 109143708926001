import styled from 'styled-components';

export const Container = styled.div`
    background-color: var(--color-background);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding: var(--padding-base);
    max-width: 600px;
    > * {
        margin-top: 2rem;
    }
`;
