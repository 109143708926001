import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Link } from 'react-router-dom';

const PfaListCard = ({ label, title, legend, href }) => {
    return (
        <Link to={href}>
            <Container>
                <h3>{label}</h3>
                <p>{title}</p>
                <span>{legend}</span>
            </Container>
        </Link>
    );
};

PfaListCard.propTypes = {
    label: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    legend: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
};

export default PfaListCard;
