import React from 'react';
import PropTypes from 'prop-types';

import { Container, Title, Content, Text, Actions, Button } from './styles';

const Dialog = ({ title, text, submit, handler, open, cancelText, submitText }) => {
    const onSubmit = () => {
        submit();
        handler();
    };

    return (
        <Container open={open} onClose={handler} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <Title size="small" id="alert-dialog-title">
                {title}
            </Title>
            <Content>
                <Text>{text}</Text>
            </Content>
            <Actions>
                <Button size="small" onClick={handler}>
                    {cancelText || 'Cancelar'}
                </Button>
                <Button size="small" onClick={() => onSubmit()}>
                    {submitText || 'Continuar'}
                </Button>
            </Actions>
        </Container>
    );
};

Dialog.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    submit: PropTypes.func.isRequired,
    handler: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    cancelText: PropTypes.string,
    submitText: PropTypes.string
};

export default Dialog;
