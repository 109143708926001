import { CircularProgress } from '@mui/material';
import Meta from 'components/templates/Seo/Meta';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logged } from '../services/auth';

const Index = () => {
    const navigate = useNavigate();

    const redirect = async () => {
        const isLogged = await logged();

        if (!isLogged) {
            navigate('/login');
            return;
        }

        navigate('/aluno');
    };

    useEffect(() => {
        redirect();
    }, []);

    return (
        <>
            <Meta />
            <CircularProgress color="inherit" />;
        </>
    );
};

export default Index;
