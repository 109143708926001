import React, { useEffect, useState } from 'react';
import { learningObjectType } from 'types/data/library';
import PropTypes from 'prop-types';
import { Container, IconContainer, Title, Footer } from './styles';
import { Article, BackupTableOutlined, Image, MenuBook, OndemandVideo, PictureAsPdf, Star, VolumeUp } from '@mui/icons-material';
import SaveCheckbox from 'components/atoms/inputs/SaveCheckbox';
import Chips from 'components/molecules/containers/Chips';
import { useNavigate } from 'react-router-dom';
import { getFileExtension } from 'utils/general';
import useSnackbar from 'hooks/useSnackbar';

const LibraryObjectCard = ({ learningObject }) => {
    const [saved, setSaved] = useState(false);
    const [fileExtension, setFileExtension] = useState('');
    const snackbar = useSnackbar();

    const navigate = useNavigate();

    const handleSave = (value) => {
        setSaved(value);

        snackbar(value ? 'Material favoritado com sucesso' : 'Material removido dos favoritos', 'success');
    };

    const redirect = () => {
        const path = window.location.pathname;
        navigate(`/aluno/biblioteca/conteudo/${learningObject.library.id}${path ? `?returnUrl=${path}` : ''}`);
    };

    useEffect(() => {
        const fileName = learningObject?.name || learningObject?.nameFile;

        setFileExtension(getFileExtension(fileName));
    }, [learningObject]);

    return (
        <Container>
            <aside>
                <IconContainer onClick={() => redirect()}>
                    {
                        {
                            1: <OndemandVideo fontSize="large" />,
                            2: <Article fontSize="large" />,
                            3: <Image fontSize="large" />,
                            4: <MenuBook fontSize="large" />,
                            5: <Star fontSize="large" />,
                            6: <VolumeUp fontSize="large" />,
                            7: <PictureAsPdf fontSize="large" />,
                            8: <BackupTableOutlined fontSize="large" />
                        }[learningObject.id_type]
                    }
                </IconContainer>
            </aside>

            <main>
                <header>
                    <Title onClick={() => redirect()}>
                        {learningObject.typeLearningObject}
                        {fileExtension && ` - ${fileExtension}`}
                    </Title>
                    <SaveCheckbox active={saved} setActive={handleSave} margin="0 -1rem 0 0" />
                </header>
                <Footer onClick={() => redirect()}>
                    <h3>
                        <span>{learningObject.library.title}</span>
                    </h3>
                    <Chips
                        onClick={() => redirect()}
                        data={learningObject.library.competence.map((item) => {
                            return { description: item?.desription || item?.description, color: item?.color };
                        })}
                    />
                </Footer>
            </main>
        </Container>
    );
};

LibraryObjectCard.propTypes = {
    learningObject: PropTypes.shape(learningObjectType)
};

export default LibraryObjectCard;
