import SubSection from 'components/molecules/structure/SubSection';
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import FullWidthContainer from 'components/atoms/structure/FullWidthContainer';
import CompetenceKnob from 'components/molecules/cards/CompetenceKnob';
import { CompetenceType } from 'types/data/competence';

const MyCompetencesCarousel = ({ competences, title, legend, courseRedirect }) => {
    if (!competences || competences.length === 0) {
        return null;
    }

    return (
        <FullWidthContainer>
            <SubSection title={title || 'Suas Competências:'} legend={legend} paddingLeft>
                <Container>
                    {competences.map((item, index) => (
                        <CompetenceKnob key={index} competence={item} courseRedirect={courseRedirect} />
                    ))}
                </Container>
            </SubSection>
        </FullWidthContainer>
    );
};

MyCompetencesCarousel.propTypes = {
    competences: PropTypes.arrayOf(PropTypes.shape(CompetenceType)).isRequired,
    title: PropTypes.string,
    legend: PropTypes.string,
    courseRedirect: PropTypes.bool
};

export default MyCompetencesCarousel;
