import React from 'react';
import { Container } from './styles';
import TimerText from '../../../atoms/texts/TimerText';
import { Link } from 'react-router-dom';
import DateVertical from 'components/atoms/texts/DateVertical';
import PropTypes from 'prop-types';
import { EventType } from 'types/data/event';
import NowText from 'components/atoms/texts/NowText';

const SmallEventCard = ({ event, returnUrl }) => {
    const { id, title, initialDate, finalDate, typeEvent } = event;

    const now = () => {
        const now = new Date();

        return now.getTime() > new Date(initialDate).getTime() && now.getTime() < new Date(finalDate).getTime();
    };

    return (
        <Link to={`/aluno/eventos/${id}${returnUrl ? `?returnUrl=${returnUrl}` : ''}`}>
            <Container>
                <aside>
                    <DateVertical dateString={initialDate.toString()} />
                </aside>
                <main>
                    <header>
                        <h2>
                            {typeEvent}: {title}
                        </h2>
                    </header>
                    <footer>
                        {now() ? <NowText /> : <TimerText initialDate={initialDate.toString()} finalDate={finalDate.toString()} />}
                    </footer>
                </main>
            </Container>
        </Link>
    );
};

SmallEventCard.propTypes = {
    event: PropTypes.shape(EventType).isRequired,
    returnUrl: PropTypes.string
};

export default SmallEventCard;
