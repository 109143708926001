import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;

    > header {
        width: 100%;
        margin-bottom: 1rem;

        > h3 {
            font-size: 1.2rem;
            color: var(--color-text);
        }
    }

    > footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
`;
