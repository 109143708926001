import React from 'react';
import {
    Home,
    Star,
    MenuBook,
    Bookmarks,
    AccountCircle,
    Help,
    Settings,
    Logout,
    Notifications,
    YouTube,
    HomeOutlined,
    MenuBookOutlined,
    BookmarksOutlined,
    AccountCircleOutlined,
    SettingsOutlined,
    NotificationsOutlined,
    StarOutline,
    HelpOutline
} from '@mui/icons-material';

export const sidebarData = [
    {
        title: 'Home',
        icon: <HomeOutlined color="primary" />,
        activeIcon: <Home color="primary" />,
        link: '/aluno',
        disabled: false
    },
    {
        title: 'Eventos',
        icon: <StarOutline color="primary" />,
        activeIcon: <Star color="primary" />,
        link: '/aluno/eventos',
        disabled: false
    },
    {
        title: 'Cursos',
        icon: <MenuBookOutlined color="primary" />,
        activeIcon: <MenuBook color="primary" />,
        link: '/aluno/cursos',
        disabled: false
    },
    {
        title: 'Favoritos',
        icon: <BookmarksOutlined color="primary" />,
        activeIcon: <Bookmarks color="primary" />,
        link: '/aluno/favoritos',
        disabled: true
    },
    {
        title: 'Biblioteca',
        icon: <YouTube color="primary" />,
        activeIcon: <YouTube color="primary" />,
        link: '/aluno/biblioteca',
        disabled: false
    },
    {
        title: 'Perfil',
        icon: <AccountCircleOutlined color="primary" />,
        activeIcon: <AccountCircle color="primary" />,
        link: '/aluno/perfil',
        disabled: false
    },
    {
        title: 'Ajuda',
        icon: <HelpOutline color="primary" />,
        activeIcon: <Help color="primary" />,
        link: '/aluno/ajuda',
        disabled: true
    },
    {
        title: 'Configurações da conta',
        icon: <SettingsOutlined color="primary" />,
        activeIcon: <Settings color="primary" />,
        link: '/aluno/configuracoes',
        disabled: true
    },
    {
        title: 'Notificações',
        icon: <NotificationsOutlined color="primary" />,
        activeIcon: <Notifications color="primary" />,
        link: '/aluno/notificacoes',
        disabled: true
    },
    {
        title: 'Sair',
        icon: <Logout color="primary" />,
        activeIcon: <Logout color="primary" />,
        link: '/logout',
        disabled: false
    }
];
