import { CircularProgress } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import { Container, Knob, ImageContainer, Level } from './styles';
import { CompetenceType } from 'types/data/competence';
import { useNavigate } from 'react-router-dom';
import { getTypeColor } from 'utils/rules/competence';

const CompetenceKnob = ({ competence, courseRedirect }) => {
    const { currentLevel, description, icon, percentNextLevel, color, id } = competence;
    const navigate = useNavigate();

    const redirect = () => {
        const path = window.location.pathname;
        const baseRoute = courseRedirect ? `/aluno/cursos/competencias/${id}` : `/aluno/perfil/competencias/${id}`;

        navigate(`${baseRoute}${path ? `?returnUrl=${path}${courseRedirect ? '&returnName=Cursos' : ''}` : ''}`);
    };

    return (
        <Container onClick={() => redirect()}>
            <Knob>
                <CircularProgress variant="determinate" value={percentNextLevel} size={90} thickness={4} color={getTypeColor(color)} />
                <main>
                    <ImageContainer>
                        {icon ? (
                            <img src={icon} alt={description} />
                        ) : (
                            <img src="https://icons.iconarchive.com/icons/icons8/windows-8/512/Files-Png-icon.png" alt="Icon" />
                        )}
                    </ImageContainer>
                </main>
            </Knob>
            <Level color={color || '#DF9B34'}>
                <span>{currentLevel}</span>
            </Level>
            <footer>
                <h3>{description}</h3>
            </footer>
        </Container>
    );
};

CompetenceKnob.propTypes = {
    competence: PropTypes.shape(CompetenceType).isRequired,
    courseRedirect: PropTypes.bool
};

export default CompetenceKnob;
