import { IconButton } from '@mui/material';
import styled, { css } from 'styled-components';

export const Container = styled.div`
    width: 100%;
    position: relative;
`;

export const Content = styled.div`
    width: 100%;
    border: 1px solid #e6e6e6;
    padding: 0.8rem;
    border-radius: var(--border-radius);

    background-color: ${(props) => props.backgroundColor};
    border-color: ${(props) => props.borderColor};

    > header {
        margin-bottom: 0.5rem;

        > span {
            color: #464646;
            font-size: 0.8rem;
            font-weight: 500;
        }
    }

    > main {
        font-size: 0.9rem;

        > p {
            font-size: 0.9rem;

            .italic {
                font-style: italic;
            }
        }
    }

    > footer {
        margin-top: 0.5rem;
        display: flex;
        justify-content: flex-end;

        > button {
            border: 1px solid ${(props) => props.borderColor};
            padding: 0.3rem 0.4rem;
            border-radius: var(--border-radius);
            font-size: 0.8rem;
            background-color: ${(props) => props.borderColor};
            color: #fff;
        }
    }
`;

export const CloseButton = styled(IconButton)`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    height: 1rem;
    width: 1rem;
`;
