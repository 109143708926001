import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import ReactPlayer from 'react-player';
import { playerType } from 'types/components/player';

const Player = ({ url, setFinished, watchClass, idStudent, stopClass }) => {
    const ref = useRef();
    const [isRunning, setIsRunning] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const handleFinish = () => {
        setFinished(true);
        stopClass(watchClass.id, idStudent, 0);
    };

    const onReady = useCallback(() => {
        if (watchClass.myStudentTimeStop === 0 || parseInt(ref.current.getDuration()) <= watchClass.myStudentTimeStop) {
            ref.current.seekTo(0, 'seconds');
            return;
        }

        const timeToStart = watchClass.myStudentTimeStop.toString();
        ref.current.seekTo(timeToStart, 'seconds');
    }, [ref.current, watchClass]);

    const checkStopClass = () => {
        return (
            ref?.current &&
            parseInt(ref.current.getCurrentTime()) !== 0 &&
            ref.current.getCurrentTime() < ref.current.getDuration() &&
            isRunning
        );
    };

    const saveTimeStopLoop = () => {
        setInterval(() => {
            if (!checkStopClass()) {
                setIsSaving(false);
                return;
            }

            stopClass(watchClass.id, idStudent, parseInt(ref.current.getCurrentTime()));
        }, 2000);
    };

    useEffect(() => {
        if (isSaving || !isRunning) {
            return;
        }

        setIsSaving(true);
        saveTimeStopLoop();
    }, [isRunning]);

    useEffect(() => {
        setIsRunning(false);
    }, [watchClass.id]);

    return (
        <Container>
            <ReactPlayer
                ref={ref}
                url={url}
                width="100%"
                height="100%"
                controls={true}
                className="react-player"
                onPause={() => setIsRunning(false)}
                onPlay={() => setIsRunning(true)}
                onEnded={() => handleFinish()}
                onReady={onReady}
                playing={true}
                playsInline
                autoPlay
            />
        </Container>
    );
};

Player.propTypes = {
    url: PropTypes.string.isRequired,
    timeStop: PropTypes.number,
    setFinished: PropTypes.func,
    watchClass: PropTypes.shape(playerType).isRequired,
    idStudent: PropTypes.number.isRequired,
    stopClass: PropTypes.func.isRequired
};

export default Player;
