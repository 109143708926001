import { api } from 'services/api';
import { getImageUrl } from 'utils/assets';

const getTimeline = async (page) => {
    const response = await api
        .get(`/timeline`, {
            params: {
                page: page
            }
        })
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: {
            data: parseTimeline(response.data?.data || []),
            lastPage: response.data.last_page
        },
        status: response.status
    };
};

const likePost = async (idPublication) => {
    const response = await api
        .post(`/like_publication`, {
            idPublication: idPublication
        })
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const parseTimeline = (timelineArray) => {
    let data = [];

    timelineArray.map((item) => {
        if (item.type_publication === 'Postagem') {
            data.push({
                id: item.id_publication,
                liked: item.publication.liked,
                likes: item.publication.likes,
                views: item.publication.saw,
                viewed: false,
                type: item.type_publication,
                typeText: 'Postagem',
                typeAdvertisement: 'Postagem',
                title: '',
                description: item.publication.description,
                competences: item.publication.competence,
                skills: item.publication.skill,
                image: item?.publication?.file ? (item?.publication?.file[0]?.url ? getImageUrl(item?.publication.file[0].url) : '') : ''
            });
        }

        if (item.type_publication === 'Oportunidade') {
            data.push({
                id: item.id_publication,
                liked: item.publication.liked,
                likes: item.publication.likes,
                views: item.publication.saw,
                viewed: false,
                type: 1,
                typeText: 'Oportunidade',
                typeAdvertisement: 'Oportunidade',
                title: `Oportunidade: ${item.publication.office || 'Título da Oportunidade'}`,
                description: item.publication.description,
                competences: [],
                company: item.publication.company,
                salary: item.publication?.salary,
                period: item.publication?.period_opportunity,
                local: item.publication?.local,
                skills: item.publication?.skill,
                image: item?.publication?.file ? (item?.publication?.file[0]?.url ? getImageUrl(item?.publication.file[0].url) : '') : ''
            });
        }

        if (item.type_publication === 'Anúncio') {
            if (item.publication.typeAdvertisement === 'Curso') {
                data.push({
                    id: item.id_publication,
                    itemId: item?.publication?.course?.id,
                    liked: item.publication.liked,
                    likes: item.publication.likes,
                    views: item.publication.saw,
                    viewed: false,
                    type: item.type_publication,
                    typeText: 'Anúncio',
                    typeAdvertisement: 'Curso',
                    title: `Curso: ${item.publication.course.name}`,
                    description: item.publication.course.description,
                    competences: item.publication.course.competence,
                    skills: [],
                    image: item?.publication?.course?.file
                        ? item?.publication?.course?.file[0]?.url
                            ? getImageUrl(item?.publication.course?.file[0].url)
                            : ''
                        : ''
                });
            }

            if (item.publication.typeAdvertisement === 'Evento') {
                data.push({
                    id: item.id_publication,
                    itemId: item?.publication?.event?.id,
                    liked: item.publication.liked,
                    likes: item.publication.likes,
                    views: item.publication.saw,
                    viewed: false,
                    type: item.type_publication,
                    typeText: 'Anúncio',
                    typeAdvertisement: 'Evento',
                    title: `Evento: ${item.publication.event.title}`,
                    description: item.publication.event.description,
                    competences: item.publication.event.competence,
                    skills: [],
                    image: item?.publication?.event?.file
                        ? item?.publication?.event?.file[0]?.url
                            ? getImageUrl(item?.publication.event?.file[0].url)
                            : ''
                        : ''
                });
            }
        }
    });

    return data;
};

export { getTimeline, likePost };
