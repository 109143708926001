import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import TimerDuration from 'components/atoms/texts/TimerDuration';
import Player from 'components/molecules/functionalities/Player';
import Line from 'components/atoms/structure/Line';
import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import Button from 'components/molecules/buttons/Button';
import { ClassType } from 'types/data/course';
import ReactHtmlParser from 'react-html-parser';
import { padNumber } from 'utils/general';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { stopClass } from 'services/courses/watch';

const WatchClass = ({ watchClass, liked, setLiked, likes, nextClass, handleFinish, lastLesson, finished, setFinished }) => {
    const [video, setVideo] = useState(null);
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const onFinish = () => {
        handleFinish();
        setFinished(true);
    };

    const redirectToHome = () => {
        navigate('/aluno/cursos');
    };

    const checkVideo = useCallback(() => {
        if (!watchClass?.learningObject) {
            setVideo(null);
            return;
        }

        const video = watchClass.learningObject.find((learningObject) => learningObject.typeLearningObject === 'Videos');

        if (video) {
            setVideo(video);
            return;
        }

        setVideo(null);
    }, [watchClass]);

    useEffect(() => {
        checkVideo();
    }, [watchClass]);

    return (
        <Container>
            <header>
                <h3>
                    Aula {padNumber(Number(watchClass.number))}: {watchClass.title}
                </h3>
                <h1>{watchClass.name}</h1>

                <TimerDuration minutes={watchClass.time} text="Tempo estimado de" />
            </header>

            {video && (
                <Player
                    url={video.url}
                    setFinished={onFinish}
                    watchClass={watchClass}
                    idStudent={auth?.user?.studentId}
                    stopClass={stopClass}
                />
            )}

            <main>{ReactHtmlParser(watchClass.description)}</main>

            <footer>
                <header>
                    <h3>Gostou da aula? Nôs dê um like!</h3>

                    <aside>
                        <FavoriteCheckbox active={liked} setActive={setLiked} noPaddingLeft />
                        <p>{likes + liked}</p>
                    </aside>
                </header>

                <Line color="#333" />

                {lastLesson ? (
                    <Button onClick={() => redirectToHome()} disabled={!finished}>
                        Finalizar curso
                    </Button>
                ) : (
                    <Button onClick={nextClass} disabled={!finished}>
                        Próximo
                    </Button>
                )}
            </footer>
        </Container>
    );
};

WatchClass.propTypes = {
    watchClass: PropTypes.shape(ClassType).isRequired,
    liked: PropTypes.bool.isRequired,
    setLiked: PropTypes.func.isRequired,
    likes: PropTypes.number.isRequired,
    nextClass: PropTypes.func.isRequired,
    handleFinish: PropTypes.func.isRequired,
    lastLesson: PropTypes.bool,
    finished: PropTypes.bool,
    setFinished: PropTypes.func
};

export default WatchClass;
