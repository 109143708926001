import styled from 'styled-components';

export const Container = styled.div`
    box-shadow: var(--box-shadow);
    padding: 1rem;
    border-radius: var(--border-radius);
    background: #ffffff;
    min-width: 15.5rem;
    max-width: 15.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
        margin-bottom: 0.5rem;
    }

    > header {
        width: 100%;
        height: 2rem;
        margin-top: -1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    > main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > span {
            font-size: 0.8rem;
            min-height: 1.2rem;
            max-height: 1.2rem;
            width: 100%;
            text-align: center;
        }

        > h2 {
            font-size: 1.2rem;
            font-weight: bold;
            width: 100%;
            text-align: center;
        }
    }

    > footer {
        width: 100%;
    }
`;
