import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Description, Title, BlankImage, DescriptionText, ItemsContainer } from './styles';
import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import Button from 'components/molecules/buttons/Button';
import { PostType } from 'types/data/post';
import ReactHtmlParser from 'react-html-parser';
import { htmlClean } from 'utils/html';
import { likePost } from 'services/timeline';
import IconText from 'components/atoms/texts/IconText';
import { Business, LocationOn, Work } from '@mui/icons-material';
import useSnackbar from 'hooks/useSnackbar';

const Post = ({ post, idPublication }) => {
    const { image, liked, local, period, description, title, company, url } = post;

    const [isLiked, setIsLiked] = useState(liked);
    const snackbar = useSnackbar();

    const handleLike = async (value) => {
        await likePost(idPublication)
            .then(() => {
                setIsLiked(value);
                snackbar(value ? 'Postagem curtida com sucesso' : 'Postagem descurtida com sucesso', 'success');
            })
            .catch((err) => {
                snackbar(err.error || 'Erro ao curtir postagem', 'error');
            });
    };

    const redirect = () => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    return (
        <Container>
            <main>
                <Title>
                    <h1>{title}</h1>
                    <aside>
                        <FavoriteCheckbox active={isLiked} setActive={handleLike} />
                    </aside>
                </Title>

                <header>{image ? <img src={image} alt={title} /> : <BlankImage />}</header>

                {(local || period || company) && (
                    <ItemsContainer>
                        {local && <IconText icon={<LocationOn />} text={local} label="Local" />}
                        {period && <IconText icon={<Work />} text={period} label="Período" />}
                        {company && <IconText icon={<Business />} text={company} label="Empresa" />}
                    </ItemsContainer>
                )}

                <Description>
                    <DescriptionText>{ReactHtmlParser(htmlClean(description))}</DescriptionText>
                </Description>
            </main>

            <footer>
                <Button onClick={() => redirect()} type="button">
                    Saber mais
                </Button>
            </footer>
        </Container>
    );
};

Post.propTypes = {
    post: PropTypes.shape(PostType).isRequired,
    idPublication: PropTypes.number.isRequired
};

export default Post;
