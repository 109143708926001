import DashboardLayout from 'components/templates/layouts/DashboardLayout';
import Home from 'pages/Aluno';
import Biblioteca from 'pages/Aluno/Biblioteca';
import LibraryContentScreen from 'pages/Aluno/Biblioteca/Conteudo';
import LibraryListScreen from 'pages/Aluno/Biblioteca/List';
import Cursos from 'pages/Aluno/Cursos';
import Watch from 'pages/Aluno/Cursos/Assistir';
import CourseCompetence from 'pages/Aluno/Cursos/Competencia';
import FinishedCourses from 'pages/Aluno/Cursos/Concluidos';
import CoursePage from 'pages/Aluno/Cursos/Curso';
import Evento from 'pages/Aluno/Eventos/Evento';
import Notificacoes from 'pages/Aluno/Notificacoes';
import Perfil from 'pages/Aluno/Perfil';
import CompetencesProfile from 'pages/Aluno/Perfil/Competencias';
import CompetenceScreen from 'pages/Aluno/Perfil/Competencias/Competence';
import Post from 'pages/Aluno/Publicacao';
import React from 'react';
import Eventos from '../../pages/Aluno/Eventos';

const StudentRoutes = [
    {
        path: '/aluno',
        element: <DashboardLayout />,
        children: [
            {
                path: '/aluno',
                element: <Home />
            },
            {
                path: '/aluno/eventos',
                element: <Eventos />
            },
            {
                path: '/aluno/cursos',
                element: <Cursos />
            },
            {
                path: '/aluno/biblioteca',
                element: <Biblioteca />
            },
            {
                path: '/aluno/perfil',
                element: <Perfil />
            }
        ]
    },
    {
        path: '/aluno',
        element: <DashboardLayout hiddenBottomNavbar />,
        children: [
            {
                path: '/aluno/eventos/:id',
                element: <Evento />
            },
            {
                path: '/aluno/cursos/competencias/:id',
                element: <CourseCompetence />
            },
            {
                path: '/aluno/cursos/concluidos',
                element: <FinishedCourses />
            },
            {
                path: '/aluno/cursos/curso/:id',
                element: <CoursePage />
            },
            {
                path: '/aluno/cursos/assistir/:id',
                element: <Watch />
            },
            {
                path: '/aluno/biblioteca/conteudo/:id',
                element: <LibraryContentScreen />
            },
            {
                path: '/aluno/biblioteca/:filter/:id',
                element: <LibraryListScreen />
            },
            {
                path: '/aluno/perfil/competencias',
                element: <CompetencesProfile />
            },
            {
                path: '/aluno/perfil/competencias/:id',
                element: <CompetenceScreen />
            },
            {
                path: '/aluno/notificacoes',
                element: <Notificacoes />
            },
            {
                path: '/aluno/publicacao/:id',
                element: <Post />
            }
        ]
    }
];

export default StudentRoutes;
