import { CircularProgress } from '@mui/material';
import Message from 'components/molecules/alerts/Message';
import BorderTitle from 'components/molecules/headers/BorderTitle';
import Course from 'components/organisms/containers/Course';
import Meta from 'components/templates/Seo/Meta';
import useQueryParam from 'hooks/useQueryParam';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getCourseById, seeCourse } from 'services/courses';

const CoursePage = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [course, setCourse] = useState({ id: 1 });
    const [competence, setCompetence] = useState({});
    const dispatch = useDispatch();
    const location = useLocation();
    const courseSelector = useSelector((state) => state.course);
    const query = useQueryParam();

    const getCompetenceData = () => {
        if (!courseSelector?.competence && !course?.competences) {
            return;
        }

        if (courseSelector?.competence?.id) {
            setCompetence(courseSelector.competence);
            return;
        }

        setCompetence({
            id: course?.competences?.[0]?.id,
            name: course?.competences?.[0]?.desription
        });
    };

    const getCourseData = async () => {
        await getCourseById(id)
            .then((response) => {
                setCourse(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.data.error);

                console.error(error);
                snackbar('Não foi possível carregar o curso.', 'error');
            });
    };

    const getReturnUrl = () => {
        const returnUrl = query.get('returnUrl');

        if (returnUrl) {
            return returnUrl;
        }

        return courseSelector?.competence?.id ? `/aluno/cursos/competencias/${courseSelector?.competence.id}` : '/aluno/cursos';
    };

    useEffect(() => {
        getCourseData();
    }, []);

    useEffect(() => {
        seeCourse(Number(id));
    }, []);

    useEffect(() => {
        getCompetenceData();
    }, [courseSelector, course]);

    useEffect(() => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: getReturnUrl(),
                returnText: 'Cursos',
                returnPath: location.pathname
            }
        });
    }, [courseSelector]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error || !course?.id) {
        return <Message text={error} />;
    }

    return (
        <>
            <Meta title="Nome do Curso" description="Descrição do Curso" />

            <BorderTitle title={competence.name || ''} />

            {course && <Course course={course} />}
        </>
    );
};

export default CoursePage;
