import { Avatar } from '@mui/material';
import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import { Container, ImageContainer, DescriptionText } from './styles';
import { TimelineItem } from 'types/data/timeline';
import { htmlClean } from 'utils/html';
import { likePost } from 'services/timeline';
import useSnackbar from 'hooks/useSnackbar';

const PostCard = ({ post }) => {
    const [liked, setLiked] = useState(post.liked);
    const snackbar = useSnackbar();

    const handleLike = async (value) => {
        await likePost(post.id)
            .then(() => {
                setLiked(value);
                snackbar(value ? 'Postagem curtida com sucesso' : 'Postagem descurtida com sucesso', 'success');
            })
            .catch((err) => {
                snackbar(err.message || 'Erro ao curtir postagem', 'error');
            });

        setLiked(value);
    };

    return (
        <Container>
            <header>
                <main>
                    <Avatar src="https://pfahomologacaoapi.rehagro.com.br/storage/images_system/rehagro.png" />

                    <h3>Equipe Rehagro</h3>
                </main>
                <aside>
                    <FavoriteCheckbox active={liked} setActive={handleLike} noPaddingLeft />
                    <p>{post.likes + liked - post.liked}</p>
                </aside>
            </header>

            <main>
                <ImageContainer>{post.image && <img src={post.image} alt={post.title} />}</ImageContainer>

                <DescriptionText>{ReactHtmlParser(htmlClean(post.description))}</DescriptionText>
            </main>
        </Container>
    );
};

PostCard.propTypes = {
    post: PropTypes.shape(TimelineItem)
};

export default PostCard;
