import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Description, Title, BlankImage } from './styles';
import DateHorizontal from 'components/atoms/texts/DateHorizontal';
import TimerText from 'components/atoms/texts/TimerText';
import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import Button from 'components/molecules/buttons/Button';
import SpeakerItem from 'components/molecules/headers/SpeakerItem';
import CheckListItem from 'components/molecules/listItems/CheckListItem';
import { EventType } from 'types/data/event';
import { likeEvent, subscribeEvent, viewEvent } from 'services/events';
import Dialog from 'components/molecules/alerts/Dialog';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSnackbar from 'hooks/useSnackbar';

const Event = ({ event }) => {
    const { id, title, description, image, competences, speakers, initialDate, finalDate, url } = event;

    const [isFavorite, setIsFavorite] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const [onLoad, setOnLoad] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const pastEvent = new Date(finalDate) < new Date();
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const snackbar = useSnackbar();

    const handleFavorite = async (value) => {
        setIsFavorite(value);

        await likeEvent(id, auth?.user?.studentId, value)
            .then(() => {
                setIsFavorite(value);
                snackbar(value ? 'Evento curtido com sucesso' : 'Evento descurtido com sucesso', 'success');
                setOnLoad(false);
            })
            .catch((err) => {
                snackbar(err.error || 'Erro ao curtir evento', 'error');
                setOnLoad(false);
            });
    };

    const handleRegister = async () => {
        setOnLoad(true);

        await subscribeEvent(id, auth?.user?.studentId, !isRegistered)
            .then((res) => {
                if (res.status === 200) {
                    setIsRegistered(!isRegistered);
                    snackbar(isRegistered ? 'Inscrição cancelada com sucesso' : 'Inscrição realizada com sucesso', 'success');
                    setOnLoad(false);
                    return;
                }

                setOnLoad(false);
            })
            .catch((err) => {
                snackbar(err.error, 'error');
                setOnLoad(false);
            });
    };

    const redirectToEvent = async () => {
        await viewEvent(id)
            .then(() => {
                window.open(url, '_blank');
                setOnLoad(false);
            })
            .catch(() => {
                window.open(url, '_blank');
                setOnLoad(false);
            });
    };

    const redirectToLibrary = async () => {
        const path = window.location.pathname;
        const libraryUri = `/aluno/biblioteca/competencia/${competences[0].id}${path ? `?returnUrl=${path}` : ''}`;

        navigate(libraryUri);
    };

    useEffect(() => {
        setIsFavorite(event.like);
        setIsRegistered(event.registered);
    }, [event]);

    return (
        <Container>
            <header>
                <aside>
                    <DateHorizontal dateString={initialDate} />
                </aside>
                <main>
                    <TimerText initialDate={initialDate} finalDate={finalDate} big flexEnd />
                    <p>Horário de Brasília</p>
                </main>
            </header>

            <main>
                <header>{image ? <img src={image} alt={title} /> : <BlankImage />}</header>
                <Title>
                    <h1>{title}</h1>
                    <FavoriteCheckbox active={isFavorite} setActive={handleFavorite} />
                </Title>

                <Description>
                    <div>
                        <h3>Palestrante{speakers.length > 0 && 's'}</h3>
                        {speakers.map((speaker) => (
                            <SpeakerItem key={speaker.id} speaker={speaker} />
                        ))}
                    </div>

                    <div>
                        <h3>Descrição</h3>
                        <p>{description}</p>
                    </div>

                    <div>
                        <h3>Evolua suas competências</h3>
                        {competences.map((item, index) => (
                            <CheckListItem text={item.desription} key={index} />
                        ))}
                    </div>
                </Description>
            </main>

            <footer>
                {!pastEvent ? (
                    <>
                        {isRegistered && (
                            <Button disabled={onLoad} color="secondary" onClick={() => redirectToEvent()} type="button">
                                Ir para o evento
                            </Button>
                        )}
                        <Button
                            disabled={onLoad}
                            color="secondary"
                            onClick={() => {
                                isRegistered ? setDialogOpen(true) : handleRegister();
                            }}
                            type="button"
                        >
                            {isRegistered ? 'Cancelar Inscrição' : 'Participar!'}
                        </Button>
                    </>
                ) : (
                    <Button disabled={onLoad} color="secondary" onClick={() => redirectToLibrary()} type="button">
                        Conteúdo Relacionado
                    </Button>
                )}
            </footer>

            <Dialog
                open={dialogOpen}
                submit={() => handleRegister()}
                title="Deseja se desiscrever do evento?"
                text='Ao clicar em "Cancelar Inscrição", você deixará participar do evento.'
                handler={() => setDialogOpen(false)}
                submitText="Cancelar Inscrição"
                cancelText="Voltar"
            />
        </Container>
    );
};

Event.propTypes = {
    event: PropTypes.shape(EventType).isRequired
};

export default Event;
