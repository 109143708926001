import { api } from 'services/api';

const getProfileById = async (id) => {
    const response = await api
        .get(`/student/${id}`)
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 200) {
        return {
            data: parsedProfiles(response.data, id),
            id,
            status: response.status
        };
    }
    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }
    return {
        data: response.data,
        status: response.status
    };
};

const getResourceEditData = async () => {
    const parseResources = (data) => {
        return {
            languages:
                data?.languages?.map((item) => {
                    return {
                        id: item.id,
                        value: item.name
                    };
                }) || [],
            states:
                data?.state?.map((item) => {
                    return {
                        id: item.id,
                        value: item.name
                    };
                }) || [],
            levelAcademicEducation:
                data?.levelAcademicEducation?.map((item) => {
                    return {
                        id: item.id,
                        value: item.description
                    };
                }) || [],
            statusAcademicEducation:
                data?.statusAcademicEducation?.map((item) => {
                    return {
                        id: item.id,
                        value: item.description
                    };
                }) || []
        };
    };

    const response = await api
        .get(`/get_resource_student`)
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token expirado');
    }

    return {
        data: parseResources(response.data),
        status: response.status
    };
};

const updateProfile = async (data, id) => {
    const response = await api
        .post(`/update_student/${id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const parsedProfiles = (profiles, id) => {
    let parsedProfileData = [];
    parsedProfileData.push(parsedProfile(profiles, id));

    return parsedProfileData;
};

const parsedProfile = (profile) => {
    profile.academicEducation.forEach((element) => {
        element.title = element.institution;
        delete element.institution;
        element.legend = element.name;
        delete element.name;
        element.footer = element.initial_date.substr(0, 4) + '-' + element.final_date.substr(0, 4);
        delete element.initial_date;
        delete element.final_date;
    });
    profile.courseAndCertificate.forEach((element) => {
        element.title = element.name;
        delete element.name;
        element.legend = element.institution;
        delete element.institution;
        element.footer = element.conclusion_date.substr(0, 4);
        delete element.conclusion_date;
    });
    profile.experience.forEach((element) => {
        element.title = element.office;
        delete element.office;
        element.legend = element.company;
        delete element.company;
        element.footer = element.initial_date.substr(0, 4) + '-' + element.final_date.substr(0, 4);
        delete element.initial_date;
        delete element.final_date;
    });
    profile.language.forEach((element) => {
        element.title = element.language;
        delete element.language;
    });

    return profile;
};

const getCities = async (idState) => {
    const response = await api
        .get(`/city`, {
            params: {
                idState: idState
            }
        })
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

export { getResourceEditData, getProfileById, getCities, updateProfile };
