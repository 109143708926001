import styled from 'styled-components';

export const Container = styled.form`
    width: 100%;

    > footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-left: 50%;
        padding-top: 2rem;
    }
`;

export const Item = styled.div`
    margin-bottom: 1rem;
`;
