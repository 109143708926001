import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const CheckListItem = ({ text }) => {
    return (
        <Container>
            <aside>
                <CheckCircleOutlineIcon />
            </aside>
            <main>
                <h4>{text}</h4>
            </main>
        </Container>
    );
};

CheckListItem.propTypes = {
    text: PropTypes.string.isRequired
};

export default CheckListItem;
