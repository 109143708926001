import styled, { css } from 'styled-components';

export const Container = styled.div`
    .MuiSvgIcon-root {
        fill: ${(props) => props.color || '#A9B2B1'};

        ${({ complete }) =>
            complete
                ? css`
                      &:nth-of-type(1) {
                          margin-bottom: -1rem;
                      }

                      &:nth-of-type(2n) {
                          margin-bottom: -0.4rem;
                      }

                      &:last-of-type {
                          margin-bottom: -1rem;
                      }
                  `
                : css`
                      &:nth-of-type(1) {
                          margin-bottom: -0.5rem;
                      }

                      &:last-of-type {
                          margin-bottom: -0.5rem;
                      }
                  `}
    }
`;
