import React from 'react';
import { Alert, Snackbar as MUISnackBar } from '@mui/material';
import { useSelector } from 'react-redux';

const Snackbar = () => {
    const snackbar = useSelector((state) => state.snackbar);

    return (
        <MUISnackBar open={snackbar?.text?.length > 0} autoHideDuration={5000} message={snackbar?.text}>
            <Alert onClose={() => changeText('')} severity={snackbar?.type || 'success'} sx={{ width: '100%' }}>
                {snackbar?.text}
            </Alert>
        </MUISnackBar>
    );
};

export default Snackbar;
