import { CircularProgress } from '@mui/material';
import FullWidthContainer from 'components/atoms/structure/FullWidthContainer';
import Message from 'components/molecules/alerts/Message';
import CompetenceHeader from 'components/molecules/headers/CompetenceHeader';
import TabsHeader from 'components/molecules/headers/TabsHeader';
import CoursesList from 'components/organisms/lists/CoursesList';
import LibraryList from 'components/organisms/lists/LibraryList';
import SmallEventList from 'components/organisms/lists/SmallEventList';
import Meta from 'components/templates/Seo/Meta';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCompetenceById, getCompetenceCourses, getCompetenceEvents, getCompetenceLibraries } from 'services/competences';

const CompetenceScreen = () => {
    const [loading, setLoading] = useState(true);
    const [loadingMoreData, setLoadingMoreData] = useState(false);
    const [competence, setCompetence] = useState(null);
    const [tab, setTab] = useState('courses');
    const [scrollY, setScrollY] = useState(0);

    const snackbar = useSnackbar();

    const [courses, setCourses] = useState([]);
    const [events, setEvents] = useState([]);
    const [libraries, setLibraries] = useState([]);

    const [page, setPage] = useState({
        libraries: 0,
        courses: 0,
        events: 0
    });

    const [lastPage, setLastPage] = useState({
        libraries: 1,
        courses: 1,
        events: 1
    });

    const dispatch = useDispatch();
    const { id } = useParams();

    const tabs = [
        {
            label: 'Cursos',
            value: 'courses'
        },
        {
            label: 'Eventos',
            value: 'events'
        },
        {
            label: 'Recursos',
            value: 'libraries'
        }
    ];

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        setScrollY(currentScrollY);
    };

    const getCompetence = async () => {
        await getCompetenceById(id)
            .then((res) => {
                setCompetence(res.data);
                setLoading(false);

                dispatch({
                    type: 'SET_COMPETENCE',
                    payload: {
                        competence: {
                            id: res.data.id,
                            name: res.data.description
                        }
                    }
                });
            })
            .catch((error) => {
                snackbar(error, 'error');
                setLoading(false);
            });
    };

    const getCourses = async () => {
        if (page.courses === lastPage.courses) {
            return;
        }

        setLoadingMoreData(true);

        await getCompetenceCourses(id, page.courses + 1)
            .then((res) => {
                setCourses([...courses, ...res.data]);
                setPage({ ...page, courses: page.courses + 1 });
                setLastPage({ ...lastPage, courses: res.lastPage });
                setLoading(false);
                setLoadingMoreData(false);
            })
            .catch((error) => {
                snackbar(error, 'error');
                setLoading(false);
            });
    };

    const getEvents = async () => {
        if (page.events === lastPage.events || loadingMoreData) {
            return;
        }

        setLoadingMoreData(true);

        await getCompetenceEvents(id, page.events + 1)
            .then((res) => {
                setEvents([...events, ...res.data]);
                setPage({ ...page, events: page.events + 1 });
                setLastPage({ ...lastPage, events: res.lastPage });
                setLoading(false);
                setLoadingMoreData(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar(typeof error === 'string' ? error : 'Erro ao buscar pelos eventos', 'error');
                setLoading(false);
            });
    };

    const getLibraries = async () => {
        if (page.libraries === lastPage.libraries || loadingMoreData) {
            return;
        }

        setLoadingMoreData(true);

        await getCompetenceLibraries(id, page.libraries + 1)
            .then((res) => {
                const newData = res.data.map((library) => {
                    return {
                        ...library,
                        id_type: library.idTypeLearningObject,
                        library: {
                            ...library,
                            id_type: library.idTypeLearningObject,
                            competence: [competence]
                        }
                    };
                });

                setLibraries([...libraries, ...newData]);
                setPage({ ...page, libraries: page.libraries + 1 });
                setLastPage({ ...lastPage, libraries: res.lastPage });
                setLoading(false);
                setLoadingMoreData(false);
            })
            .catch((error) => {
                snackbar(error, 'error');
                setLoading(false);
            });
    };

    const getMoreData = async () => {
        switch (tab) {
            case 'courses':
                getCourses();
                break;
            case 'events':
                getEvents();
                break;
            case 'libraries':
                getLibraries();
                break;
            default:
                break;
        }
    };

    const verifyScroll = () => {
        if (scrollY !== 0 && !loadingMoreData) {
            if (document.body.scrollHeight - scrollY - 30 < window.innerHeight) {
                getMoreData();
                return;
            }
        }
    };

    useEffect(() => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: '/aluno/perfil/competencias',
                returnText: 'Minhas competências',
                returnPath: location.pathname
            }
        });
    }, []);

    useEffect(() => {
        verifyScroll();
    }, [scrollY]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        getCompetence();
    }, []);

    useEffect(() => {
        if (tab === 'courses') {
            getCourses();
        }

        if (tab === 'events') {
            getEvents();
        }

        if (tab === 'libraries') {
            getLibraries();
        }
    }, [tab]);

    if (loading) {
        return (
            <>
                <Meta title="Minhas competências" />
                <CircularProgress />
            </>
        );
    }

    if (!competence) {
        return (
            <>
                <Meta title="Minhas competências" />
                <Message text="Competência não encontrada" />
            </>
        );
    }

    return (
        <>
            <Meta title="Minhas competências" />

            <CompetenceHeader competence={competence} />

            <FullWidthContainer padding=" 0 1rem 4rem 1rem">
                <TabsHeader tabs={tabs} tab={tab} setTab={setTab} />

                {
                    {
                        courses: <CoursesList courses={courses} marginTop />,
                        events: <SmallEventList events={events} />,
                        libraries: <LibraryList learningObjects={libraries} courses={[]} filterSearch={{}} marginTop />
                    }[tab]
                }
            </FullWidthContainer>
        </>
    );
};

export default CompetenceScreen;
