import { CircularProgress } from '@mui/material';
import Message from 'components/molecules/alerts/Message';
import Event from 'components/organisms/containers/Event';
import Meta from 'components/templates/Seo/Meta';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getEventById, seeEvent } from 'services/events';
import useQueryParam from 'hooks/useQueryParam';

const Evento = () => {
    const { id } = useParams();
    const [event, setEvent] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const query = useQueryParam();

    const getEvent = async () => {
        if (!auth?.user || event?.id) {
            return;
        }

        const data = await getEventById(id, auth.user.studentId).then((response) => response);

        if (data.status !== 200) {
            setLoading(false);
            setError(data.data.error);
        }

        if (data.status === 401) {
            navigate('/login');
            return;
        }

        setEvent(data.data);
        setLoading(false);
    };

    const getReturnUrl = () => {
        const returnUrl = query.get('returnUrl');

        if (returnUrl) {
            return returnUrl;
        }

        return '/aluno/eventos';
    };

    useEffect(() => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: getReturnUrl(),
                returnText: 'Eventos',
                returnPath: location.pathname
            }
        });
    }, []);

    useEffect(() => {
        seeEvent(Number(id));
    }, []);

    useEffect(() => {
        getEvent(id);
    }, [id, auth]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error || !event?.id) {
        return <Message text={error} />;
    }

    return (
        <>
            <Meta title={event.title} description={event.description} />
            {event && <Event event={event} />}
        </>
    );
};

export default Evento;
