import { Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Button as MUIButton } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Dialog)``;

export const Title = styled(DialogTitle)`
    font-size: 1rem;
    padding: 0.8rem;
`;

export const Content = styled(DialogContent)`
    padding: 0.8rem;
`;

export const Text = styled(DialogContentText)`
    font-size: 0.9rem;
`;

export const Actions = styled(DialogActions)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const Button = styled(MUIButton)`
    width: auto;
    font-size: 0.8rem;
    text-transform: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
`;
