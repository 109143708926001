import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { Bookmark, BookmarkBorder } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const SaveCheckbox = ({ active, setActive, fontSize, margin }) => {
    return (
        <Container fontSize={fontSize} margin={margin}>
            <IconButton aria-label="toggle password visibility" onClick={() => setActive(!active)}>
                {active ? <Bookmark /> : <BookmarkBorder />}
            </IconButton>
        </Container>
    );
};

SaveCheckbox.propTypes = {
    active: PropTypes.bool.isRequired,
    setActive: PropTypes.func.isRequired,
    fontSize: PropTypes.string,
    margin: PropTypes.string
};

export default SaveCheckbox;
