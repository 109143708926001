import styled from 'styled-components';
import { Avatar } from '@mui/material';

export const Container = styled.div`
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > p {
        font-size: 0.7rem;
    }

    .MuiAvatarGroup-root {
        > * {
            min-width: 40px;
            min-height: 40px;
            max-width: 40px;
            max-height: 40px;
        }
    }
`;

export const Image = styled(Avatar)``;
