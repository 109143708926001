import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    background-color: #fff;
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;

    > input {
        width: 100%;
        padding: 0.8rem;
        border-radius: var(--border-radius);
        border: 1px solid #c4c4c4;
    }

    > button {
        position: absolute;
        right: 0.5rem;
        background-color: transparent;
        border: none;
        color: var(--color-primary);
    }
`;
