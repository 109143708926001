import styled from 'styled-components';

export const Container = styled.div`
    background-color: #fff;
    position: fixed;
    width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    margin: 0;
    margin-left: ${(props) => (props.active ? '0' : '-200vw')};
    transition: var(--transition);
    z-index: 15;
    top: 0;
    left: 0;
    overflow: auto;

    ::-webkit-scrollbar {
        border-radius: 10px;
        width: 0.4rem;
        height: 0.4rem;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #bbb;
    }

    ::-webkit-scrollbar-thumb:hover {
        border-radius: 10px;
        background: #aaa;
    }

    > header {
        padding: 2rem 1rem 1rem 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #333;
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 5;

        > h2 {
            font-size: 1.5rem;
            color: var(--color-primary);
        }

        > button {
            color: var(--color-primary);
            padding: 0;
            margin: 0;
        }
    }

    > main {
        min-height: calc(100vh - 160px);
        overflow: auto;
        max-width: 100%;

        ::-webkit-scrollbar {
            border-radius: 10px;
            width: 0.4rem;
            height: 0.4rem;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #bbb;
        }

        ::-webkit-scrollbar-thumb:hover {
            border-radius: 10px;
            background: #aaa;
        }
    }

    > footer {
        z-index: 8;
        background-color: #fff;
        padding: 1rem 1rem 1rem 1rem;
        position: sticky;
        bottom: 0;
        border-top: 1px solid #000;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        height: 80px;
    }

    @media screen and (min-width: 768px) {
        width: 600px;
        margin-left: ${(props) => (props.active ? '0' : '-600px')};
    }
`;

export const Item = styled.div`
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid #ddd;
`;
