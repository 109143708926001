import { Avatar } from '@mui/material';
import styled from 'styled-components';

export const ContainerHeader = styled.div`
    background-image: url('https://pfahomologacaoapi.rehagro.com.br/storage/images_competences/Rectangle_726.png');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 6rem;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;

    @media screen and (min-width: 600px) {
        border-radius: 0 0 8px 8px;
    }
`;

export const ImageContainer = styled(Avatar)`
    border-radius: 50%;
    border: 4px solid var(--color-background);
    height: 80px;
    width: 80px;
    margin-bottom: -1.5rem;
`;

export const EditProfileButton = styled.button`
    background: #ffffff;
    border-radius: 4px;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
`;

export const ContainerFooter = styled.div`
    width: 100%;
    padding-top: 0.8rem;

    > h2 {
        color: var(--color-black);
        font-size: 24px;
        font-weight: 700;
    }

    > h3 {
        padding-top: 0.2rem;
        font-weight: 400;
        font-size: 16px;
        color: var(--color-black);
    }

    > p {
        padding-top: 0.4rem;
        font-weight: 400;
        font-size: 12px;
        color: var(--color-black);
    }
`;
