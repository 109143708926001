import { api } from 'services/api';
import { getImageUrl } from 'utils/assets';
import { dateJsToISO } from 'utils/date';

const getEvents = async (studentId) => {
    const response = await api
        .get(`/event_client`)
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 200) {
        return {
            data: parsedEvents(response.data, studentId),
            status: response.status
        };
    }

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    return {
        data: response.data,
        status: response.status
    };
};

const getEventById = async (id, studentId) => {
    const response = await api
        .get(`/event/${id}`)
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 200) {
        return {
            data: parsedEvent(response.data, studentId),
            status: response.status
        };
    }

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    return {
        data: response.data,
        status: response.status
    };
};

const subscribeEvent = async (eventId, studentId, subscribe) => {
    const response = await api
        .post(`/registered_event`, {
            idEvent: eventId,
            idStudent: studentId.toString(),
            registered: Number(subscribe)
        })
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status !== 200) {
        return {
            data: response.data,
            status: response.status
        };
    }

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    return {
        data: response.data,
        status: response.status
    };
};

const likeEvent = async (eventId, studentId, like) => {
    const response = await api
        .post(`/like_event`, {
            idEvent: eventId,
            idStudent: studentId.toString(),
            like: Number(like)
        })
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const viewEvent = async (eventId) => {
    const response = await api
        .post(`/view_event`, {
            idEvent: eventId
        })
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const seeEvent = async (eventId) => {
    const response = await api
        .post(`/saw_event`, {
            idEvent: eventId
        })
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const getFilterData = async () => {
    const response = await api
        .get(`/get_resource_event`)
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: {
            eventTypes: response.data.typeEvents.map((type) => type.description),
            competences: response.data.competences.map((competence) => competence?.description || competence?.desription)
        },
        status: response.status
    };
};

const parsedEvents = (events, studentId = null) => {
    if (!events) return [];

    let parsedEventsData = [];

    events.forEach((event) => {
        if (event.active || new Date() <= new Date(event.finalDate)) {
            parsedEventsData.push(parsedEvent(event, studentId));
        }
    });

    return parsedEventsData;
};

const parsedEvent = (event, studentId = null) => {
    return {
        id: event?.id,
        title: event?.title,
        point: event?.point,
        initialDate: dateJsToISO(event?.initialDate ? event.initialDate : event.initial_date),
        finalDate: dateJsToISO(event?.finalDate ? event.finalDate : event.final_date),
        description: event?.description,
        active: event?.active,
        typeEvent: event?.typeEvent,
        image: event?.file ? (event?.file[0]?.url ? getImageUrl(event?.file[0].url) : '') : '',
        speakers: event?.speaker,
        competences: event?.competence,
        registered: event?.registered?.some((registered) => registered?.id_student === studentId),
        like: event?.like?.some((like) => like?.id_student === studentId),
        likes: event?.like?.length,
        registers: event?.registered?.length,
        url: event?.url
    };
};

export { getEvents, getEventById, getFilterData, subscribeEvent, likeEvent, viewEvent, seeEvent, parsedEvents, parsedEvent };
