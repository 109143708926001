import { CircularProgress } from '@mui/material';
import BorderTitle from 'components/molecules/headers/BorderTitle';
import CoursesCarousel from 'components/molecules/carousels/CoursesCarousel';
import Meta from 'components/templates/Seo/Meta';
import React, { useEffect, useState } from 'react';
import { getCompletedCourses, getParticipatingCourses, removeCourseList } from 'services/courses';
import MyCompetencesCarousel from 'components/molecules/carousels/MyCompetencesCarousel';
import { getStudentCompetences } from 'services/competences';
import useSnackbar from 'hooks/useSnackbar';

const Cursos = () => {
    const [participatingCourses, setParticipatingCourses] = useState([]);
    const [competences, setCompetences] = useState([]);
    const [concludedCourses, setConcludedCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const snackbar = useSnackbar();

    const getParticipatingCoursesData = async () => {
        await getParticipatingCourses(true)
            .then((response) => {
                setParticipatingCourses(response.data);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar cursos', 'error');
            });
    };

    const getConcludedCoursesData = async () => {
        await getCompletedCourses()
            .then((response) => {
                setConcludedCourses(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Não foi possível carregar os cursos.', 'error');
            });
    };

    const getCompetencesData = async () => {
        await getStudentCompetences()
            .then((response) => {
                setCompetences(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar competências', 'error');
            });
    };

    const removeCompletedCourse = (courseId) => {
        removeCourseList(courseId);
        const newParticipatingCourses = participatingCourses.filter((course) => course.id !== courseId);
        setParticipatingCourses(newParticipatingCourses);
    };

    useEffect(() => {
        getParticipatingCoursesData();
        getCompetencesData();
        getConcludedCoursesData();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            <Meta title="Cursos" description="Visualize todos os cursos da nossa plataforma" />

            <BorderTitle title="Cursos" />

            {competences?.length > 0 && (
                <MyCompetencesCarousel
                    competences={competences}
                    title="Cursos para o seu desenvolvimento:"
                    legend="Explore as Competências:"
                    courseRedirect
                />
            )}

            <CoursesCarousel courses={participatingCourses} removeCompletedCourse={removeCompletedCourse} />

            <CoursesCarousel courses={concludedCourses} removeCompletedCourse={removeCompletedCourse} finishedCourses />
        </>
    );
};

export default Cursos;
