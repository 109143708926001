import React from 'react';
import PropTypes from 'prop-types';
import { Container, LabelContainer, Line, LineContainer } from './styles';
import Typography from '@mui/material/Typography';

const Progress = ({ progress, color }) => {
    return (
        <Container>
            <LineContainer>
                <Line variant="determinate" value={progress} color={color || 'primary'} />
            </LineContainer>
            <LabelContainer>
                <Typography variant="body2" color="text.secondary">
                    {Math.round(progress)}%
                </Typography>
            </LabelContainer>
        </Container>
    );
};

Progress.propTypes = {
    progress: PropTypes.number,
    color: PropTypes.string
};

export default Progress;
