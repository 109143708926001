import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SubSection from 'components/molecules/structure/SubSection';
import { FormatType } from 'types/data/format';
import { CompetenceType } from 'types/data/competence';
import { useEffect } from 'react';
import { cleanAccents } from 'utils/general';
import { Container } from './styles';
import GridLibrary from 'components/molecules/containers/GridLibrary';

const Library = ({ search, formats, competences }) => {
    const [filteredFormats, setFilteredFormats] = useState(formats || []);
    const [filteredCompetences, setFilteredCompetences] = useState(competences || []);

    const pipe = (array, attr) => {
        if (!array) return [];

        return array.map((item) => {
            return {
                id: item.id,
                value: item[attr],
                icon: item?.icon,
                color: item?.color
            };
        });
    };

    useEffect(() => {
        if (search) {
            setFilteredCompetences(
                pipe(
                    competences.filter((competence) => {
                        return cleanAccents(competence.description.toLowerCase()).includes(cleanAccents(search.toLowerCase()));
                    }),
                    'description'
                )
            );

            return;
        }

        setFilteredCompetences(pipe(competences, 'description'));
    }, [search, competences]);

    useEffect(() => {
        if (search) {
            setFilteredFormats(
                pipe(
                    formats.filter((format) => {
                        return cleanAccents(format.description.toLowerCase()).includes(cleanAccents(search.toLowerCase()));
                    }),
                    'description'
                )
            );

            return;
        }

        setFilteredFormats(pipe(formats, 'description'));
    }, [search, formats]);

    return (
        <>
            <SubSection title="Pesquisar por Competências:">
                <Container>
                    <GridLibrary data={filteredCompetences} type="competence" errorText="Nenhuma competência encontrada" />
                </Container>
            </SubSection>

            <SubSection title="Pesquisar por tipo de arquivo:">
                <Container>
                    <GridLibrary data={filteredFormats} type="format" errorText="Nenhum tipo de arquivo encontrado" />
                </Container>
            </SubSection>
        </>
    );
};

Library.propTypes = {
    search: PropTypes.string.isRequired,
    formats: PropTypes.arrayOf(PropTypes.shape(FormatType)).isRequired,
    competences: PropTypes.arrayOf(PropTypes.shape(CompetenceType)).isRequired
};

export default Library;
