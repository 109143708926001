import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Image } from './styles';
import { AvatarGroup } from '@mui/material';
import useWindowSize from 'hooks/useWindowSize';
import { userType } from 'types/data/user';

const InlineStudents = ({ total, users }) => {
    const size = useWindowSize();
    const [max, setMax] = useState(4);

    useEffect(() => {
        if (size.width <= 330) {
            setMax(2);
            return;
        }

        if (size.width <= 370) {
            setMax(3);
            return;
        }

        setMax(4);
    }, [size.width]);

    return (
        <Container>
            <p>Pessoas aprendendo junto com você:</p>
            <AvatarGroup total={total >= 99 + 3 ? 99 + 3 : total} max={max}>
                {users.map((user, index) => (
                    <Image key={index} src={user.image || ''}>
                        {user.name[0].toUpperCase()}
                    </Image>
                ))}
            </AvatarGroup>
        </Container>
    );
};

InlineStudents.propTypes = {
    total: PropTypes.number.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape(userType)).isRequired
};

export default InlineStudents;
