import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    > aside {
        margin-right: 1rem;
        width: 4rem;
        height: 4rem;

        img {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
        }

        > * {
            min-width: 4rem;
            min-height: 4rem;
        }
    }

    > main {
        > h4 {
            font-size: 1.3rem;
            color: #333;
        }

        > p {
            font-size: 0.8rem;
            font-weight: 500;
            color: #555;
        }
    }
`;
