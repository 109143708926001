import styled from 'styled-components';
import { css } from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    background-color: ${(props) => props.background || 'transparent'};

    ${(props) =>
        props.marginTop !== undefined &&
        css`
            margin-top: ${props.marginTop};
        `}

    ${(props) =>
        props.padding !== undefined &&
        css`
            padding: ${props.padding};
        `}

    ${(props) =>
        props.sticky &&
        css`
            position: sticky;
            top: var(--navbar-height);
            z-index: 5;
        `}
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
    max-width: 600px;
    flex-direction: column;
`;
