import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { CameraAlt } from '@mui/icons-material';
import Button from 'components/molecules/buttons/Button';

const Dropzone = ({ onFileUploaded, label }) => {
    const [selectedFileUrl, setSelectedFileUrl] = useState('');

    const onDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];

            const fileUrl = URL.createObjectURL(file);

            setSelectedFileUrl(fileUrl);
            onFileUploaded(file);
        },
        [onFileUploaded]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*'
    });

    return (
        <Container {...getRootProps()}>
            <input {...getInputProps()} accept="image/*" />

            {selectedFileUrl ? (
                <img src={selectedFileUrl} alt="Point Thumbnail" />
            ) : (
                <p>
                    <CameraAlt fontSize="large" />
                    <Button color="light" width="auto" size="thin" onClick={() => {}}>
                        {label}
                    </Button>
                </p>
            )}
        </Container>
    );
};

Dropzone.propTypes = {
    onFileUploaded: PropTypes.func.isRequired,
    label: PropTypes.object.isRequired
};

export default Dropzone;
