import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Item } from './styles';
import TextInput from 'components/atoms/formInputs/TextInput';
import DropdownInput from 'components/atoms/formInputs/DropdownInput';
import TextareaInput from 'components/atoms/formInputs/TextareaInput';
import { Formik } from 'formik';
import { Validator } from 'utils/validators';

import FormList from 'components/atoms/formInputs/FormList';
import DateInput from 'components/atoms/formInputs/DateInput';
import MultiSelectInput from 'components/atoms/formInputs/MultiSelectInput';
import CheckboxInput from 'components/atoms/formInputs/CheckboxInput';
import Button from 'components/molecules/buttons/Button';
import DropzoneInput from 'components/atoms/formInputs/DropzoneInput';

const FormMaker = ({ data, formSubmit }) => {
    const [initialValues, setInitialValues] = useState({});
    const [validationSchema, setValidationSchema] = useState({});

    const [submitTry, setSubmitTry] = useState(false);

    const getValidationSchema = () => {
        const schema = {};
        data.map((line) => {
            line.map((item) => {
                schema[item.id] = item?.validation;
            });
        });
        return schema;
    };

    const getInitialValues = () => {
        const initialValues = {};
        data.map((line) => {
            line.map((item) => {
                if (item.type === 'checkbox') {
                    initialValues[item.id] = item?.initialValue || false;
                    return;
                }

                initialValues[item.id] = item?.initialValue || '';
            });
        });
        return initialValues;
    };

    useEffect(() => {
        setInitialValues(getInitialValues());
        setValidationSchema(getValidationSchema());
    }, [data]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Validator.object().shape(validationSchema)}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    setSubmitting(true);
                    formSubmit(values);
                } catch (error) {
                    console.error(error);
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <Container
                    onSubmit={(e) => {
                        e.preventDefault();
                        setSubmitTry(true);
                        handleSubmit();
                    }}
                >
                    {data.map((line, index) => (
                        <div key={index}>
                            {line.map((item, key) => (
                                <Item key={key} marginLeft={key > 0}>
                                    {item?.conditionAttr && item?.conditionValue && !item?.conditionValue(values[item.conditionAttr])
                                        ? null
                                        : {
                                              text: (
                                                  <TextInput
                                                      handleChange={handleChange}
                                                      handleBlur={handleBlur}
                                                      value={values[item.id]}
                                                      error={touched[item.id] || submitTry ? errors[item.id] : ''}
                                                      id={item.id}
                                                      item={item}
                                                  />
                                              ),
                                              dropdown: (
                                                  <DropdownInput
                                                      handleChange={handleChange}
                                                      handleBlur={handleBlur}
                                                      value={values[item.id]}
                                                      error={touched[item.id] || submitTry ? errors[item.id] : ''}
                                                      id={item.id}
                                                      item={item}
                                                  />
                                              ),
                                              multiSelect: (
                                                  <MultiSelectInput
                                                      handleChange={handleChange}
                                                      handleBlur={handleBlur}
                                                      value={values[item.id]}
                                                      error={touched[item.id] || submitTry ? errors[item.id] : ''}
                                                      id={item.id}
                                                      item={item}
                                                  />
                                              ),
                                              checkbox: (
                                                  <CheckboxInput handleBlur={handleBlur} value={values[item.id]} id={item.id} item={item} />
                                              ),
                                              textarea: (
                                                  <TextareaInput
                                                      handleChange={handleChange}
                                                      handleBlur={handleBlur}
                                                      value={values[item.id]}
                                                      item={item}
                                                      error={touched[item.id] || submitTry ? errors[item.id] : ''}
                                                      id={item.id}
                                                  />
                                              ),
                                              date: (
                                                  <DateInput
                                                      handleBlur={handleBlur}
                                                      value={values[item.id]}
                                                      item={item}
                                                      error={touched[item.id] || submitTry ? errors[item.id] : ''}
                                                      id={item.id}
                                                  />
                                              ),
                                              dropfile: (
                                                  <DropzoneInput
                                                      onFileUploaded={() => {}}
                                                      item={item}
                                                      handleChange={handleChange}
                                                      handleBlur={handleBlur}
                                                  />
                                              ),
                                              formList: (
                                                  <FormList
                                                      handleChange={handleChange}
                                                      handleBlur={handleBlur}
                                                      errors={errors}
                                                      touched={touched}
                                                      values={values}
                                                      item={item}
                                                      submitTry={submitTry}
                                                  />
                                              )
                                          }[item.type]}
                                </Item>
                            ))}
                        </div>
                    ))}
                    <footer>
                        <Button type="submit" color="secondary" disabled={isSubmitting} onClick={() => handleSubmit()}>
                            Salvar
                        </Button>
                    </footer>
                </Container>
            )}
        </Formik>
    );
};

FormMaker.propTypes = {
    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
    formSubmit: PropTypes.func.isRequired
};

export default FormMaker;
