import React, { useState } from 'react';
import { Container, Content, MenuButton } from './styles';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../../../../assets/images/logo.png';
import MainSidebar from '../MainSidebar';
import { HelpOutline, NotificationsOutlined } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getFallbackUrl } from 'utils/general';
import { useSelector } from 'react-redux';

const MainNavbar = () => {
    const [openSidebar, setOpenSidebar] = useState(false);
    const notifications = useSelector((state) => state.notifications);

    const navigate = useNavigate();

    const redirectToNotifications = () => {
        navigate(getFallbackUrl('/aluno/notificacoes'));
    };

    return (
        <>
            <Container>
                <Content>
                    <aside>
                        <MenuButton aria-label="menu" onClick={() => setOpenSidebar(true)}>
                            <MenuIcon color="primary" />
                        </MenuButton>

                        <img src={Logo} alt="Logo do Rehagro" />
                    </aside>
                    <main>
                        <IconButton aria-label="menu" onClick={() => redirectToNotifications()}>
                            <Badge badgeContent={notifications.total} color="primary">
                                <NotificationsOutlined color="primary" />
                            </Badge>
                        </IconButton>
                        <IconButton aria-label="menu">
                            <HelpOutline color="primary" />
                        </IconButton>
                    </main>
                </Content>
            </Container>

            <MainSidebar active={openSidebar} handler={() => setOpenSidebar(false)} />
        </>
    );
};

export default MainNavbar;
