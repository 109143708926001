import { api } from 'services/api';

const finishClass = async (classId, studentId) => {
    const response = await api
        .post(`/complete_class`, {
            idClass: classId,
            idStudent: studentId,
            complete: 1
        })
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const stopClass = async (classId, studentId, time) => {
    const response = await api
        .post(`/time_stop_class`, {
            idClass: classId,
            idStudent: studentId,
            timeStop: time
        })
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

export { finishClass, stopClass };
