import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const SubSection = ({ title, legend, children, borderTop, paddingLeft }) => {
    return (
        <Container borderTop={borderTop} paddingLeft={paddingLeft}>
            <header>
                <h2>{title}</h2>
                {legend && <p>{legend}</p>}
            </header>
            <main>{children}</main>
        </Container>
    );
};

SubSection.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    borderTop: PropTypes.bool,
    paddingLeft: PropTypes.bool,
    legend: PropTypes.string
};

export default SubSection;
