import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Step, Stepper, StepLabel } from '@mui/material';
import { ClassType } from 'types/data/course';
import { padNumber } from 'utils/general';

const ClassesList = ({ classes, changeClass, currentClassNumber, maxClassNumber, finished, finishedCourse }) => {
    const clickHandler = (index) => {
        if (finished && index === maxClassNumber + 1) {
            changeClass(index);
            return;
        }

        if (index === currentClassNumber || index > maxClassNumber) {
            return;
        }

        changeClass(index);
    };

    const activeStep = () => {
        if (!finished && currentClassNumber === maxClassNumber) {
            return currentClassNumber - 1;
        }

        if (finished && currentClassNumber === maxClassNumber) {
            return currentClassNumber;
        }

        if (currentClassNumber === maxClassNumber) {
            return currentClassNumber;
        }

        return maxClassNumber - !finishedCourse;
    };

    return (
        <Container>
            <Stepper activeStep={activeStep()} orientation="vertical">
                {classes.map((classItem, index) => (
                    <Step key={index}>
                        <StepLabel onClick={() => clickHandler(Number(classItem.number))}>
                            {Number(classItem.number) === currentClassNumber ? (
                                <b>
                                    Aula {padNumber(Number(classItem.number))}: {classItem.title}
                                </b>
                            ) : (
                                `Aula ${padNumber(Number(classItem.number))}: ${classItem.title}`
                            )}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Container>
    );
};

ClassesList.propTypes = {
    classes: PropTypes.arrayOf(PropTypes.shape(ClassType)).isRequired,
    changeClass: PropTypes.func.isRequired,
    currentClassNumber: PropTypes.number.isRequired,
    maxClassNumber: PropTypes.number.isRequired,
    finished: PropTypes.bool.isRequired,
    finishedCourse: PropTypes.bool.isRequired
};

export default ClassesList;
