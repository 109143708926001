import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Terrain } from '@mui/icons-material';

const ProfileAboutCard = ({ title, legend, footer, image }) => {
    return (
        <Container>
            <aside>
                {image ? (
                    <img src={image} alt={title} />
                ) : (
                    <div>
                        <Terrain fontSize="large" />
                    </div>
                )}
            </aside>
            <main>
                <h3>{title}</h3>
                <p>{legend}</p>
                <span>{footer}</span>
            </main>
        </Container>
    );
};

ProfileAboutCard.propTypes = {
    title: PropTypes.string.isRequired,
    legend: PropTypes.string.isRequired,
    footer: PropTypes.string.isRequired,
    image: PropTypes.string
};

export default ProfileAboutCard;
