import styled from 'styled-components';

export const Container = styled.div`
    padding-top: 1rem;
    width: 100%;

    > * {
        margin-bottom: 1rem;
    }

    > footer {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
