import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    overflow: auto;
    width: 100%;
    padding: 0.5rem 1rem 0.8rem 1rem;
    max-width: var(--max-width);

    > * {
        margin-right: 0.5rem;
    }

    ::-webkit-scrollbar {
        border-radius: 10px;
        width: 0.4rem;
        height: 0.4rem;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #bbb;
    }

    ::-webkit-scrollbar-thumb:hover {
        border-radius: 10px;
        background: #aaa;
    }

    @media screen and (max-width: 768px) {
        ::-webkit-scrollbar {
            border-radius: 10px;
            width: 0.1rem;
            height: 0.1rem;
        }
    }
`;

export const Footer = styled.footer`
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    > button {
        background-color: transparent;
        border: none;
        font-weight: 700;
        color: var(--color-primary);
    }
`;
