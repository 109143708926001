import React from 'react';
import { Container, Content, MenuButton } from './styles';
import { ArrowBackIosNew } from '@mui/icons-material';
import { Help } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SubNavbar = ({ title, url }) => {
    return (
        <Container>
            <Content>
                <Link to={url}>
                    <aside>
                        <MenuButton aria-label="menu">
                            <ArrowBackIosNew color="primary" />
                        </MenuButton>

                        <h2>{title}</h2>
                    </aside>
                </Link>

                <main>
                    <IconButton aria-label="menu">
                        <Help color="primary" />
                    </IconButton>
                </main>
            </Content>
        </Container>
    );
};

SubNavbar.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

export default SubNavbar;
