import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import CourseCard from 'components/molecules/cards/CourseCard';
import Message from 'components/molecules/alerts/Message';
import { CourseType } from 'types/data/course';
import SmallCourseCard from 'components/molecules/cards/SmallCourseCard';

const CoursesList = ({ courses, small, emptyMessage, marginTop }) => {
    const path = window.location.pathname;

    if (!courses.length) {
        return <Message text={emptyMessage || 'Nenhum curso encontrado nessa competência'} />;
    }

    if (small) {
        return (
            <Container>
                {courses.map((course, index) => (
                    <SmallCourseCard key={index} course={course} path={path} />
                ))}
            </Container>
        );
    }

    return (
        <Container marginTop={marginTop}>
            {courses.map((course, index) => (
                <CourseCard key={index} course={course} />
            ))}
        </Container>
    );
};

CoursesList.propTypes = {
    courses: PropTypes.arrayOf(PropTypes.shape(CourseType)).isRequired,
    small: PropTypes.bool,
    emptyMessage: PropTypes.string,
    marginTop: PropTypes.bool
};

export default CoursesList;
