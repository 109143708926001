import { combineReducers } from 'redux';
import authReducer from './authReducer';
import courseReducer from './courseReducer';
import notificationsReducer from './notificationsReducer';
import routerReducer from './routerReducer';
import snackbarReducer from './snackbarReducer';

const reducer = combineReducers({
    auth: authReducer,
    router: routerReducer,
    course: courseReducer,
    notifications: notificationsReducer,
    snackbar: snackbarReducer
});

export default reducer;
