import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from 'components/molecules/structure/Backdrop';
import FormMaker from 'components/organisms/layout/form/FormMaker';
import { getCities, getResourceEditData, updateProfile } from 'services/profile';
import { Validator } from 'utils/validators';
import { useSelector } from 'react-redux';

const EditProfile = ({ active, handler, data }) => {
    const [getProfile, setGetProfile] = useState(false);
    const [resource, setResource] = useState({});
    const auth = useSelector((state) => state.auth);

    const formSubmit = async (values) => {
        const formData = new FormData();

        console.log(values);

        formData.append('name', values?.name);
        formData.append('occupation', values?.cargo);
        formData.append('idState', Number(values?.idState));
        formData.append('idCity', Number(values?.idCity));
        formData.append('description', values?.about);

        if (values?.image) {
            formData.append('image', values?.image);
        }

        const languagesFormData = values?.language?.map((languageId) => {
            return {
                idLanguage: languageId
            };
        });

        if (languagesFormData) {
            formData.append('languages', languagesFormData);
        }

        const courseAndCertificateFormData = values?.certifications?.map((certification) => {
            return {
                name: certification?.name,
                conclusionDate: certification?.conclusionDate.toISOString(),
                institution: certification?.institution
            };
        });

        if (courseAndCertificateFormData) {
            formData.append('courseAndCertificate', courseAndCertificateFormData);
        }

        const experienceFormData = values?.profissionalExperiences?.map((profissionalExperience) => {
            return {
                office: profissionalExperience?.name,
                company: profissionalExperience?.institution,
                initialDate: profissionalExperience?.startDate.toISOString(),
                finalDate: profissionalExperience?.endDate.toISOString()
            };
        });

        if (experienceFormData) {
            formData.append('experience', experienceFormData);
        }

        const academicEducationFormData = values?.academicEducation?.map((education) => {
            return {
                name: education?.name,
                institution: education?.institution,
                idLevelAcademicEducation: education?.level,
                initialDate: education?.startDate?.toISOString(),
                finalDate: education?.endDate ? education?.endDate?.toISOString() : null,
                idStatusAcademicEducation: education?.status,
                endForecast: education?.forecastDate?.toISOString()
            };
        });

        if (academicEducationFormData) {
            formData.append('academicEducation', academicEducationFormData);
        }

        await updateProfile(formData, auth.user.studentId).then(() => {
            snackbar('Perfil atualizado com sucesso', 'success');
            setGetProfile(true);
        });
    };

    const getData = async () => {
        await getResourceEditData()
            .then((response) => {
                setResource(response.data);
                setGetProfile(true);
            })
            .catch((error) => {
                snackbar(error, 'error');
            });
    };

    const getCitiesData = async (stateId) => {
        if (stateId) {
            await getCities(stateId)
                .then((response) => {
                    setResource({
                        ...resource,
                        cities:
                            response.data.map((item) => {
                                return {
                                    value: item.name,
                                    id: item.id
                                };
                            }) || []
                    });
                    setGetProfile(true);
                })
                .catch((error) => {
                    snackbar(error.message, 'error');
                });
        }
    };

    const form = [
        [
            {
                id: 'image',
                type: 'dropfile',
                label: 'Subir imagem de perfil'
            }
        ],
        [
            {
                id: 'name',
                label: 'Nome Completo',
                type: 'text',
                validation: Validator.string().max(255).required('O nome é obrigatório')
            }
        ],
        [
            {
                id: 'cargo',
                label: 'Cargo atual',
                type: 'text',
                validation: Validator.string().max(255).required('O cargo é obrigatório')
            }
        ],
        [
            {
                id: 'idState',
                label: 'Estado',
                type: 'dropdown',
                onChange: getCitiesData,
                validation: Validator.number()
                    .equals([resource?.states?.map((item) => item.id)], 'Estado inválido')
                    .required('O estado é obrigatório'),
                options: resource?.states || []
            }
        ],
        [
            {
                id: 'idCity',
                label: 'Cidade',
                type: 'dropdown',
                validation: Validator.number()
                    .equals([resource?.cities?.map((item) => item.id)], 'Cidade inválida')
                    .required('O estado é obrigatório'),
                options: resource?.cities || []
            }
        ],
        [
            {
                id: 'about',
                label: 'Sobre',
                type: 'textarea',
                validation: Validator.string()
            }
        ],
        [
            {
                id: 'languages',
                type: 'multiSelect',
                label: 'Idiomas',
                validation: Validator.array(Validator.number()),
                options: resource?.languages || []
            }
        ],
        [
            {
                id: 'academicEducation',
                type: 'formList',
                label: 'Formação Acadêmica',
                buttonLabel: 'Adicionar nova Formação Acadêmica',
                validation: Validator.array(
                    Validator.object({
                        name: Validator.string().required('Nome é obrigatório'),
                        institution: Validator.string().required('Instituição de ensino é obrigatório'),
                        level: Validator.number()
                            .equals([resource?.levelAcademicEducation?.map((item) => item.id)], 'Nível inválido')
                            .required('O nível do curso é obrigatório'),
                        status: Validator.number()
                            .equals([resource?.statusAcademicEducation?.map((item) => item.id)], 'Status inválido')
                            .required('O status do curso é obrigatório'),
                        forecastDate: Validator.date().when('status', {
                            is: 1,
                            then: Validator.date().required('Data de término obrigatória')
                        }),
                        endDate: Validator.date().when('status', {
                            is: 0,
                            then: Validator.date().required('Data de término obrigatória')
                        }),
                        startDate: Validator.date().required('Data de início obrigatória')
                    })
                ),
                formData: [
                    [
                        {
                            id: 'name',
                            label: 'Nome do curso',
                            type: 'text'
                        }
                    ],
                    [
                        {
                            id: 'institution',
                            label: 'Instituição de Ensino',
                            type: 'text'
                        }
                    ],
                    [
                        {
                            id: 'level',
                            label: 'Nível do Curso',
                            type: 'dropdown',
                            options: resource?.levelAcademicEducation || []
                        }
                    ],
                    [
                        {
                            id: 'status',
                            label: 'Status do Curso',
                            type: 'dropdown',
                            options: resource?.statusAcademicEducation || []
                        }
                    ],
                    ,
                    [
                        {
                            id: 'startDate',
                            label: 'Data de início',
                            type: 'date',
                            maxDate: new Date()
                        }
                    ],
                    [
                        {
                            id: 'forecastDate',
                            label: 'Previsão de término',
                            type: 'date',
                            conditionAttr: 'status',
                            conditionValue: (status) => Number(status) === 1,
                            minDate: new Date()
                        }
                    ],
                    [
                        {
                            id: 'endDate',
                            label: 'Data de término',
                            type: 'date',
                            conditionAttr: 'status',
                            conditionValue: (status) => Number(status) !== 1,
                            maxDate: new Date()
                        }
                    ]
                ]
            }
        ],
        [
            {
                id: 'certifications',
                type: 'formList',
                label: 'Curso ou Certificado',
                buttonLabel: 'Adicionar Curso ou Certificado',
                validation: Validator.array(
                    Validator.object({
                        name: Validator.string().required('Nome é obrigatório'),
                        institution: Validator.string().required('Instituição de ensino é obrigatório'),
                        conclusionDate: Validator.date().required('Data de conclusão é obrigatória')
                    })
                ),
                formData: [
                    [
                        {
                            id: 'name',
                            label: 'Nome do curso',
                            type: 'text'
                        }
                    ],
                    [
                        {
                            id: 'institution',
                            label: 'Instituição de Ensino',
                            type: 'text'
                        }
                    ],
                    [
                        {
                            id: 'conclusionDate',
                            label: 'Data de conclusão',
                            type: 'date',
                            maxDate: new Date()
                        }
                    ]
                ]
            }
        ],
        [
            {
                id: 'profissionalExperiences',
                type: 'formList',
                label: 'Experiência Profissional',
                buttonLabel: 'Adicionar Experiência Profissional',
                validation: Validator.array(
                    Validator.object({
                        name: Validator.string().required('Nome é obrigatório'),
                        institution: Validator.string().required('Instituição de ensino é obrigatório'),
                        startDate: Validator.date().required('Data de início é obrigatória'),
                        working: Validator.boolean(),
                        endDate: Validator.date().when('working', {
                            is: false,
                            then: Validator.date().required('Data de término necessário')
                        })
                    })
                ),
                formData: [
                    [
                        {
                            id: 'name',
                            label: 'Nome do cargo',
                            type: 'text'
                        }
                    ],
                    [
                        {
                            id: 'institution',
                            label: 'Empresa',
                            type: 'text'
                        }
                    ],
                    [
                        {
                            id: 'startDate',
                            label: 'Data de início',
                            type: 'date',
                            maxDate: new Date()
                        }
                    ],
                    [
                        {
                            id: 'working',
                            label: 'Trabalhando atualmente',
                            type: 'checkbox'
                        }
                    ],
                    [
                        {
                            id: 'endDate',
                            label: 'Data de término',
                            type: 'date',
                            conditionAttr: 'working',
                            conditionValue: (working) => !working
                        }
                    ]
                ]
            }
        ]
    ];

    useEffect(() => {
        if (getProfile || !active) {
            return;
        }

        getData();
    }, [active]);

    return (
        <>
            <Container active={active}>
                <header>
                    <h2>Editar Perfil</h2>
                    <IconButton onClick={() => handler()}>
                        <CloseIcon fontSize="large" color="primary" />
                    </IconButton>
                </header>

                <main>
                    <FormMaker data={form} formSubmit={formSubmit} />
                </main>
            </Container>
            <Backdrop active={active} handler={handler} />
        </>
    );
};

EditProfile.propTypes = {
    setModal: PropTypes.func,
    active: PropTypes.bool,
    handler: PropTypes.func,
    data: PropTypes.object
};

export default EditProfile;
