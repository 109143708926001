import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Search } from '@mui/icons-material';

const SearchInput = ({ value, onChange }) => {
    return (
        <Container>
            <input type="text" value={value} onChange={(e) => onChange(e.target.value)} placeholder="Digite o que está procurando" />
            <button>
                <Search />
            </button>
        </Container>
    );
};

SearchInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default SearchInput;
