import { Tab, Tabs } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { tabType } from 'types/components/tab';

const TabsHeader = ({ tab, setTab, tabs }) => {
    const changeTab = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Container>
            <Tabs value={tab} onChange={changeTab} aria-label="Eventos" variant="fullWidth">
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab.label} value={tab.value} />
                ))}
            </Tabs>
        </Container>
    );
};

TabsHeader.propTypes = {
    tab: PropTypes.string.isRequired,
    setTab: PropTypes.func.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape(tabType)).isRequired
};

export default TabsHeader;
