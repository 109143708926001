import { Avatar } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const UserSidebar = ({ name, email }) => {
    return (
        <Container>
            <aside>
                <Avatar>{name[0].toUpperCase()}</Avatar>
            </aside>
            <main>
                <h4>{name}</h4>
                <p>{email}</p>
            </main>
        </Container>
    );
};

UserSidebar.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
};

export default UserSidebar;
