import Index from '../../pages';
import React from 'react';
import BlankLayout from '../../components/templates/layouts/BlankLayout';

const MainRoutes = {
    path: '/',
    element: <BlankLayout />,
    children: [
        {
            path: '/',
            element: <Index />
        }
    ]
};

export default MainRoutes;
