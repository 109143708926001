import { api } from 'services/api';
import { parsedCourses } from 'services/courses';

const getLibraryParameters = async () => {
    const response = await api
        .get(`/get_resource_library`)
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    if (response.status !== 200) {
        return {
            data: response.data,
            status: response.status
        };
    }

    return {
        data: response.data,
        status: response.status
    };
};

const getLibraryData = async (filter, id) => {
    const response = await api
        .get(`/get_library_client`, {
            params: {
                idTypeLearningObject: filter === 'formato' ? id : '',
                idCompetence: filter === 'competencia' ? id : ''
            }
        })
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    const course = parsedCourses(response.data?.course || []);

    return {
        data: {
            learningObjects:
                response.data?.learningObject?.sort((a, b) => {
                    const x = a.library.title.toLowerCase();
                    const y = b.library.title.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                }) || [],
            courses:
                course?.sort((a, b) => {
                    const x = a.title.toLowerCase();
                    const y = b.title.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                }) || []
        },
        status: response.status
    };
};

const getLibraryById = async (id) => {
    const response = await api
        .get(`/library/${id}`)
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: parseLibrary(response.data),
        status: response.status
    };
};

const likeLibrary = async (idStudent, idLibrary, like) => {
    const response = await api
        .post(`/like_library`, {
            idStudent,
            idLibrary,
            like
        })
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const parseLibrary = (library) => {
    return {
        id: library?.id,
        title: library?.title,
        description: library?.description,
        point: library?.point,
        created_at: library?.created_at,
        updated_at: library?.updated_at,
        competences: library?.competence.map((item) => {
            return {
                id: item?.id,
                description: item?.desription,
                color: item?.color
            };
        }),
        post: library?.post,
        time: library?.time,
        learningObject: library?.learningObject?.length > 0 ? library?.learningObject[0] : null
    };
};

export { getLibraryParameters, getLibraryData, getLibraryById, likeLibrary };
