import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ActionsArea, BlankImage, Container, TitleContainer, BodyCard } from './styles';
import { IconButton } from '@mui/material';
import { Bookmark, BookmarkBorder } from '@mui/icons-material';
import Button from 'components/molecules/buttons/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { favoriteCourse } from 'services/courses';
import { htmlClean } from 'utils/html';
import { CourseType } from 'types/data/course';

const CourseCard = ({ course, completed, pathName }) => {
    const { id, title, description, time, speakers, isFavorite, image, finalDate } = course;

    const hours = Math.floor(time / 60);

    const [favorited, setFavorited] = useState(isFavorite);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const path = window.location.pathname;
    const redirectUrl = `/aluno/cursos/curso/${id}${path ? `?returnUrl=${path}` : ''}${pathName ? `&returnName=${pathName}` : ''}`;

    const handleFavorite = async (value) => {
        await favoriteCourse(id, auth?.user?.studentId, value)
            .then(() => {
                setFavorited(value);
                dispatch({
                    type: 'SET_NOTIFICATION',
                    payload: {
                        text: value ? 'Curso favoritado com sucesso' : 'Curso desfavoritado com sucesso',
                        type: 'success'
                    }
                });
            })
            .catch((err) => {
                dispatch({
                    type: 'SET_NOTIFICATION',
                    payload: {
                        text: err.message || 'Erro ao favoritar curso',
                        type: 'error'
                    }
                });
            });
    };

    const redirectToCourse = () => {
        navigate(redirectUrl);
    };

    return (
        <Container>
            <header>
                <Link to={redirectUrl}>{image ? <img src={image} alt={title} /> : <BlankImage />} </Link>

                <TitleContainer>
                    <main>
                        <Link to={redirectUrl}>
                            <>
                                <h2>{title}</h2>
                                <p>Por {speakers[0].name}</p>
                            </>
                        </Link>
                    </main>
                    <ActionsArea>
                        <IconButton aria-label="toggle password visibility" onClick={() => handleFavorite(!favorited)}>
                            {favorited ? <Bookmark /> : <BookmarkBorder />}
                        </IconButton>
                    </ActionsArea>
                </TitleContainer>
            </header>
            <Link to={redirectUrl}>
                <BodyCard>
                    <main>{ReactHtmlParser(htmlClean(description).split('</p>')[0])}</main>
                    <footer>
                        {completed && finalDate ? <p>Concluído em {finalDate}</p> : <p>{hours} horas totais</p>}

                        <Button type="button" size="small" width="auto" onClick={() => redirectToCourse()} color="neutral">
                            {completed ? 'Rever' : 'Saiba mais'}
                        </Button>
                    </footer>
                </BodyCard>
            </Link>
        </Container>
    );
};

CourseCard.propTypes = {
    course: PropTypes.shape(CourseType).isRequired,
    completed: PropTypes.bool,
    pathName: PropTypes.string
};
export default CourseCard;
