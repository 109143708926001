import { api } from 'services/api';

const getUsers = async () => {
    const response = await api
        .get(`/student_random`)
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 200) {
        return {
            data: {
                students: response.data?.student || [],
                total: response.data?.countStudent || 0
            },
            status: response.status
        };
    }

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    return {
        data: response.data,
        status: response.status
    };
};

export { getUsers };
