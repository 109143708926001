import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > * {
        padding-top: var(--padding-small);
    }

    > h1 {
        font-size: 1.3rem;
        font-weight: 700;
        color: var(--color-primary);
        text-align: center;
    }

    > p {
        font-size: var(--font-size-body);
        text-align: center;
    }
`;
