import { api } from 'services/api';

const getNotifications = async (page = 1) => {
    const response = await api
        .get(`/notification`, {
            params: {
                page
            }
        })
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status !== 200) {
        return {
            data: response.data,
            status: response.status
        };
    }

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    return {
        data: { ...response.data, data: parseNotifications(response.data.data) },
        status: response.status
    };
};

const getNotificationsTotal = async () => {
    const response = await api
        .get(`/notification`)
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status !== 200) {
        return {
            data: response.data,
            status: response.status
        };
    }

    if (response.status === 401) {
        window.location.href = '/login';
        return null;
    }

    return {
        data: response.data.total,
        status: response.status
    };
};

const sawNotification = async (idNotification) => {
    const response = await api
        .post(`/saw_notification`, {
            idNotification
        })
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

const parseNotifications = (notifications) => {
    return notifications.map((notification) => parseNotification(notification));
};

const parseNotification = (notification) => {
    const getTypeNotification = () => {
        if (notification.id_course) {
            return 'course';
        }

        if (notification.id_event) {
            return 'event';
        }

        if (notification.method === 'profile_incomplete') {
            return 'profile';
        }

        return 'other';
    };

    return {
        id: notification.id,
        backgroundColor: notification.backgroundColor,
        borderColor: notification.borderColor,
        button: notification.button,
        text: notification.description,
        type: getTypeNotification(),
        idCourse: notification?.id_course,
        idEvent: notification?.id_event,
        date: new Date(notification?.created_at),
        method: notification?.method
    };
};

export { getNotifications, sawNotification, getNotificationsTotal };
