import React from 'react';
import PropTypes from 'prop-types';

import { Container, Content } from './styles';
import { Outlet } from 'react-router-dom';

const BlankLayout = () => {
    return (
        <Container>
            <Content>
                <Outlet />
            </Content>
        </Container>
    );
};

BlankLayout.propTypes = {
    children: PropTypes.node
};

export default BlankLayout;
