import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    padding: 0.5rem;
    width: 100%;

    > aside {
        min-width: 126px;
        margin-right: 1.2rem;
        min-height: 126px;
        position: relative;

        > img {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            background-position: center;
            background-size: cover;
            object-fit: cover;
        }
    }

    > main {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > header {
            > h3 {
                font-size: 0.9rem;
            }
            margin-bottom: 0.5rem;
        }

        > footer {
            width: 100%;

            > * {
                margin-bottom: 0.5rem;
            }

            button {
                float: right;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;
