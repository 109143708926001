import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    background-color: #fff;

    > h3 {
        font-size: 1rem;
        color: var(--color-black);
    }
    > p {
        font-size: 0.9rem;
        font-weight: 700;
        color: var(--color-black);
    }
    > span {
        font-size: 0.8rem;
        color: var(--color-gray);
    }
`;
