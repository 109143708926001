import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: ${(props) => (props.borderTop ? '1rem 0 0 0' : '0')};
    border-top: ${(props) => (props.borderTop ? '1px solid #E0E0E0' : 'none')};

    > header {
        width: 100%;

        > h2 {
            font-size: 1rem;
            width: 100%;
            padding-left: ${(props) => (props.paddingLeft ? '1rem' : '0')};
            color: var(--color-black);
        }

        > p {
            font-size: 0.8rem;
            width: 100%;
            padding-left: ${(props) => (props.paddingLeft ? '1rem' : '0')};
            color: var(--color-black);
            margin-bottom: 0.5rem;
        }
    }

    > main {
        width: 100%;
    }
`;
