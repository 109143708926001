import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import Button from 'components/molecules/buttons/Button';
import { Link, useNavigate } from 'react-router-dom';

const FinishedCourseCard = ({ course }) => {
    const { id, title, image } = course;
    const navigate = useNavigate();
    const path = window.location.pathname;
    const uri = `/aluno/cursos/assistir/${id}${path ? `?returnUrl=${path}` : ''}`;

    const redirectToWatch = () => {
        navigate(uri);
    };

    return (
        <Container>
            <Link to={uri}>
                <aside>{image ? <img src={image} alt={title} /> : <img src="https://via.placeholder.com/126" alt={title} />}</aside>
            </Link>
            <main>
                <header>
                    <h3>{title}</h3>
                </header>
                <footer>
                    <Button size="small" type="button" width="auto" onClick={() => redirectToWatch()} color="neutral">
                        Rever
                    </Button>
                </footer>
            </main>
        </Container>
    );
};

FinishedCourseCard.propTypes = {
    course: PropTypes.object.isRequired
};

export default FinishedCourseCard;
