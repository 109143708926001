import SaveCheckbox from 'components/atoms/inputs/SaveCheckbox';
import TimerDuration from 'components/atoms/texts/TimerDuration';
import Chips from 'components/molecules/containers/Chips';
import React, { useState } from 'react';

import { AsideHeader, Container, Description, FormatText } from './styles';
import Player from 'components/molecules/functionalities/Player';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { htmlClean } from 'utils/html';
import Button from 'components/molecules/buttons/Button';
import LikedContainer from 'components/molecules/containers/LikedContainer';
import PropTypes from 'prop-types';

import { getFileExtension } from 'utils/general';
import { libraryType } from 'types/data/library';
import { CircularProgress } from '@mui/material';
import FileSaver from 'file-saver';
import PdfViewer from 'components/molecules/functionalities/PdfViewer';
import { likeLibrary } from 'services/library';
import useSnackbar from 'hooks/useSnackbar';

const LibraryContent = ({ data }) => {
    const [liked, setLiked] = useState(false);
    const [favorited, setFavorited] = useState(false);
    const auth = useSelector((state) => state.auth);

    const { id, title, description, competences, time, learningObject } = data;

    const fileFormat = getFileExtension(learningObject.name);

    const downloadOptions = ['Imagens', 'Textos', 'PDF'];

    const snackbar = useSnackbar();

    const handleLike = async (value) => {
        await likeLibrary(auth.user.id, id, value)
            .then(() => {
                setLiked(value);
                snackbar(value ? 'Material curtido com sucesso' : 'Material descurtido com sucesso', 'success');
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao curtir material', 'error');
            });
    };

    const handleFavorite = async (value) => {
        setFavorited(value);
        snackbar(value ? 'Material favoritado com sucesso' : 'Material removido dos favoritos com sucesso', 'success');
    };

    const onDownload = async () => {
        FileSaver.saveAs(learningObject.url, `${title.split(' ')[0]}.${fileFormat || 'pdf'}`);
    };

    if (!data) {
        return <CircularProgress />;
    }

    return (
        <Container>
            <header>
                <h1>{title}</h1>
                <Chips data={competences} />

                {time && <TimerDuration minutes={time} text="Tempo estimado" unit=" h" />}

                <AsideHeader>
                    <SaveCheckbox active={favorited} setActive={handleFavorite} />
                </AsideHeader>
            </header>

            <main>
                {
                    {
                        Imagens: <img src={learningObject.url} alt={learningObject.name} />,
                        Videos: (
                            <Player
                                url={learningObject.url}
                                setFinished={() => {}}
                                watchClass={{
                                    id: 99,
                                    myStudentTimeStop: 0
                                }}
                                idStudent={auth?.user?.studentId}
                                stopClass={() => {}}
                            />
                        ),
                        Textos: <PdfViewer file={learningObject.url} />,
                        PDF: <PdfViewer file={learningObject.url} />
                    }[learningObject.typeLearningObject]
                }

                <Description>{ReactHtmlParser(htmlClean(description).split('</p>')[0])}</Description>
            </main>

            <footer>
                {fileFormat && <FormatText>Arquivo Formato: {fileFormat}</FormatText>}

                {downloadOptions.includes(learningObject.typeLearningObject) && (
                    <Button color="secondary" onClick={() => onDownload()}>
                        Baixar Arquivo
                    </Button>
                )}

                <LikedContainer liked={liked} setLiked={handleLike} likes={0} />
            </footer>
        </Container>
    );
};

LibraryContent.propTypes = {
    data: PropTypes.shape(libraryType)
};

export default LibraryContent;
