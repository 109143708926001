import RememberPassword from 'pages/Auth/RememberPassword';
import React from 'react';
import BlankLayout from '../../components/templates/layouts/BlankLayout';
import Login from '../../pages/Auth/Login';

const AuthenticationRoutes = {
    path: '/',
    element: <BlankLayout />,
    children: [
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/remember_password',
            element: <RememberPassword />
        }
    ]
};

export default AuthenticationRoutes;
