import SaveCheckbox from 'components/atoms/inputs/SaveCheckbox';
import Button from 'components/molecules/buttons/Button';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import FullWidthContainer from 'components/atoms/structure/FullWidthContainer';
import ClassesMenu from 'components/organisms/menus/ClassesMenu';
import { ClassType } from 'types/data/course';

const WatchHeader = ({ title, classes, favorite, handleFavorite, progress, activeClassNumber, maxClassNumber, changeClass, finished }) => {
    const [classesMenu, setClassesMenu] = useState(false);

    const handleClass = (value) => {
        setClassesMenu(false);
        changeClass(value);
    };

    return (
        <FullWidthContainer background="#fff" marginTop={0}>
            <Container>
                <header>
                    <h3>{title}</h3>
                </header>
                <footer>
                    <Button size="small" width="auto" color="neutral" onClick={() => setClassesMenu(true)}>
                        Menu das aulas
                    </Button>
                    <SaveCheckbox active={favorite} setActive={handleFavorite} fontSize="1.8" />
                </footer>
            </Container>

            <ClassesMenu
                active={classesMenu}
                handler={() => setClassesMenu(false)}
                classes={classes}
                progress={progress}
                currentClassNumber={activeClassNumber}
                maxClassNumber={maxClassNumber}
                changeClass={handleClass}
                finished={finished}
            />
        </FullWidthContainer>
    );
};

WatchHeader.propTypes = {
    title: PropTypes.string.isRequired,
    classes: PropTypes.arrayOf(PropTypes.shape(ClassType)).isRequired,
    favorite: PropTypes.bool.isRequired,
    progress: PropTypes.number.isRequired,
    activeClassNumber: PropTypes.number.isRequired,
    maxClassNumber: PropTypes.number.isRequired,
    changeClass: PropTypes.func.isRequired,
    finished: PropTypes.bool.isRequired,
    handleFavorite: PropTypes.func.isRequired
};

export default WatchHeader;
