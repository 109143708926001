import Dropzone from 'components/molecules/functionalities/Dropzone';
import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

const DropzoneInput = ({ value, error, item }) => {
    const { setFieldValue } = useFormikContext();

    const onFileUploaded = (file) => {
        setFieldValue(item.id, file);
    };

    return <Dropzone onFileUploaded={onFileUploaded} label={item.label} />;
};

DropzoneInput.propTypes = {
    item: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    error: PropTypes.string,
    id: PropTypes.string
};

export default DropzoneInput;
