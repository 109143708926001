import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const EventColumn = styled.div`
    width: 100%;
    border-bottom: 1px #aaa solid;
    margin-bottom: 1rem;

    > header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding-bottom: 0.5rem;

        > h2 {
            font-size: 1.1rem;
        }
    }

    > main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        > * {
            margin-bottom: 1rem;
        }
    }

    &:last-child {
        border-bottom: none;
    }
`;
