import { Divider, IconButton } from '@mui/material';
import Button from 'components/molecules/buttons/Button';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Container, InputItem, Item } from './styles';
import TextInput from '../TextInput';
import { Close, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { FieldArray } from 'formik';
import DropdownInput from '../DropdownInput';
import TextareaInput from '../TextareaInput';
import DateInput from '../DateInput';
import MultiSelectInput from '../MultiSelectInput';
import CheckboxInput from '../CheckboxInput';

const FormList = ({ item, values, errors, touched, handleChange, handleBlur, submitTry }) => {
    const [showForm, setShowForm] = useState(true);

    const getEmptyValueOfForm = (form) => {
        const emptyValue = {};
        form.map((line) => {
            line.map((item) => {
                emptyValue[item.id] = '';
            });
        });

        return emptyValue;
    };

    const getFormListFieldError = (errors, itemId, index, fieldId) => {
        if (errors[itemId]) {
            if (errors[itemId][index]) {
                if (errors[itemId][index][fieldId]) {
                    return errors[itemId][index][fieldId];
                }
            }
        }
        return '';
    };

    return (
        <Container showForm={showForm}>
            <Divider />
            <header>
                <h2>{item?.label}</h2>
                <IconButton aria-label="toggle password visibility" onClick={() => setShowForm(!showForm)} edge="end" size="large">
                    {showForm ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
            </header>

            <main>
                <FieldArray name={item.id}>
                    {({ push, remove }) => (
                        <>
                            {values[item.id]?.map((_, index) => (
                                <Item key={index}>
                                    {item.formData.map((formLine, formLineIndex) => (
                                        <div key={formLineIndex}>
                                            {formLine.map((formItem, formItemIndex) => {
                                                const id = `${item.id}[${index}][${formItem.id}]`;
                                                const touchedInput = touched[item.id]?.[index]?.[formItem.id];
                                                const error =
                                                    touchedInput || submitTry
                                                        ? getFormListFieldError(errors, item.id, index, formItem.id)
                                                        : '';
                                                const value = values[item.id]?.[index]?.[formItem.id];

                                                if (
                                                    formItem?.conditionAttr &&
                                                    formItem?.conditionValue &&
                                                    !formItem.conditionValue(values[item.id]?.[index]?.[formItem?.conditionAttr])
                                                ) {
                                                    return null;
                                                }

                                                return (
                                                    <InputItem key={formItemIndex} marginLeft={formItemIndex > 0}>
                                                        {
                                                            {
                                                                text: (
                                                                    <TextInput
                                                                        item={formItem}
                                                                        handleChange={handleChange}
                                                                        handleBlur={handleBlur}
                                                                        value={value}
                                                                        error={error}
                                                                        id={id}
                                                                    />
                                                                ),
                                                                dropdown: (
                                                                    <DropdownInput
                                                                        item={formItem}
                                                                        handleChange={handleChange}
                                                                        handleBlur={handleBlur}
                                                                        value={value}
                                                                        error={error}
                                                                        id={id}
                                                                    />
                                                                ),
                                                                textarea: (
                                                                    <TextareaInput
                                                                        item={formItem}
                                                                        handleChange={handleChange}
                                                                        handleBlur={handleBlur}
                                                                        value={value}
                                                                        error={error}
                                                                        id={id}
                                                                    />
                                                                ),
                                                                date: (
                                                                    <DateInput
                                                                        handleBlur={handleBlur}
                                                                        value={value}
                                                                        item={formItem}
                                                                        error={error}
                                                                        id={id}
                                                                    />
                                                                ),
                                                                multiSelect: (
                                                                    <MultiSelectInput
                                                                        handleBlur={handleBlur}
                                                                        handleChange={handleChange}
                                                                        value={value}
                                                                        item={formItem}
                                                                        error={error}
                                                                        id={id}
                                                                    />
                                                                ),
                                                                checkbox: (
                                                                    <CheckboxInput
                                                                        handleBlur={handleBlur}
                                                                        value={value}
                                                                        item={formItem}
                                                                        id={id}
                                                                    />
                                                                )
                                                            }[formItem.type]
                                                        }
                                                    </InputItem>
                                                );
                                            })}
                                        </div>
                                    ))}

                                    <Button
                                        type="button"
                                        color="error"
                                        size="thin"
                                        onClick={() => {
                                            remove(index);
                                        }}
                                    >
                                        <Close />
                                    </Button>
                                </Item>
                            ))}

                            <Button
                                type="button"
                                size="thin"
                                color="light"
                                onClick={() => {
                                    push(getEmptyValueOfForm(item?.formData));
                                }}
                            >
                                {item.buttonLabel}
                            </Button>
                        </>
                    )}
                </FieldArray>
            </main>
        </Container>
    );
};

FormList.propTypes = {
    item: PropTypes.object.isRequired,
    values: PropTypes.arrayOf(PropTypes.object).isRequired,
    errors: PropTypes.arrayOf(PropTypes.object).isRequired,
    touched: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    submitTry: PropTypes.bool.isRequired
};

export default FormList;
