import React from 'react';
import { House, FavoriteBorder, MenuBook, Star, YouTube } from '@mui/icons-material';

export const bottomNavbarData = [
    {
        title: 'Home',
        icon: <House />,
        link: '/aluno',
        disabled: false
    },
    {
        title: 'Eventos',
        icon: <Star />,
        link: '/aluno/eventos',
        disabled: false
    },
    {
        title: 'Cursos',
        icon: <MenuBook />,
        link: '/aluno/cursos',
        disabled: false
    },
    {
        title: 'Favoritos',
        icon: <FavoriteBorder />,
        link: '/aluno/favoritos',
        disabled: true
    },
    {
        title: 'Biblioteca',
        icon: <YouTube />,
        link: '/aluno/biblioteca',
        disabled: false
    }
];
