import { CircularProgress } from '@mui/material';
import MyCompetencesCarousel from 'components/molecules/carousels/MyCompetencesCarousel';
import MyCommitments from 'components/organisms/containers/MyCommitments';
import UserInfo from 'components/organisms/containers/UserInfo';
import Timeline from 'components/organisms/lists/Timeline';
import Meta from 'components/templates/Seo/Meta';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getStudentCompetences } from 'services/competences';
import { getParticipatingCourses } from 'services/courses';
import { getEvents } from 'services/events';
import { getUsers } from 'services/home';
import { getTimeline } from 'services/timeline';

const Home = () => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const auth = useSelector((state) => state.auth);

    const [competences, setCompetences] = useState([]);
    const [participatingCourses, setParticipatingCourses] = useState([]);
    const [participatingEvents, setParticipatingEvents] = useState([]);
    const [scrollY, setScrollY] = useState(0);
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);

    const [loadingPosts, setLoadingPosts] = useState(true);
    const [posts, setPosts] = useState([]);
    const [postsPage, setPostsPage] = useState(0);
    const [postsLastPage, setPostsLastPage] = useState(0);

    const snackbar = useSnackbar();

    const getCompetencesData = async () => {
        if (!auth?.user?.studentId) {
            return;
        }

        await getStudentCompetences(auth.user.studentId)
            .then((response) => {
                setCompetences(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar competências', 'error');
            });
    };

    const getParticipatingCoursesData = async () => {
        await getParticipatingCourses()
            .then((response) => {
                setParticipatingCourses(response.data.slice(0, 3));
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar cursos', 'error');
            });
    };

    const getEventsData = async () => {
        if (!auth?.user?.studentId) {
            return;
        }

        await getEvents(auth.user.studentId)
            .then((response) => {
                setParticipatingEvents(response.data.slice(0, 3));
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar eventos', 'error');
            });
    };

    const getUserData = async () => {
        if (!auth?.user?.studentId) {
            return;
        }

        setUser({
            email: auth.user?.email,
            id: auth.user?.id,
            roleId: auth.user?.roleId,
            studentId: auth.user?.studentId,
            name: auth.user?.name,
            level: 'Semente',
            image: ''
        });

        setLoading(false);
        setLoadingPosts(false);
    };

    const getPostsData = async () => {
        if ((postsPage === postsLastPage && postsPage !== 0) || loadingPosts) {
            return;
        }

        const currentPage = postsPage + 1;
        setPostsPage(currentPage);
        setLoadingPosts(true);

        await getTimeline(currentPage)
            .then((response) => {
                setPosts([...posts, ...response.data.data]);
                setPostsLastPage(response.data.lastPage);

                setTimeout(() => {
                    setLoadingPosts(false);
                }, 2000);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar posts', 'error');
            });
    };

    const getUsersData = async () => {
        await getUsers()
            .then((response) => {
                setUsers(response.data.students);
                setTotalUsers(response.data.total);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar usuários', 'error');
            });
    };

    const verifyScroll = () => {
        if (scrollY !== 0 && !loadingPosts) {
            if (document.body.scrollHeight - scrollY - 30 < window.innerHeight) {
                getPostsData();
                return;
            }
        }
    };

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        setScrollY(currentScrollY);
    };

    useEffect(() => {
        verifyScroll();
    }, [scrollY]);

    useEffect(() => {
        getCompetencesData();
        getUserData();
        getParticipatingCoursesData();
        getEventsData();
        getPostsData();
        getUsersData();
    }, [auth]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            <Meta title="Home" />

            <UserInfo user={user} users={users} totalStudents={totalUsers} />

            <MyCompetencesCarousel competences={competences} />

            <MyCommitments courses={participatingCourses} events={participatingEvents} />

            <Timeline posts={posts} lastPage={postsLastPage === postsPage} />
        </>
    );
};

export default Home;
