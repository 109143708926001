import SubSection from 'components/molecules/structure/SubSection';
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import ProfileAboutCard from 'components/molecules/cards/ProfileAboutCard';

const ProfileListAbout = ({ title, data }) => {
    return (
        <SubSection title={title} borderTop>
            <Container>
                {data !== undefined &&
                    data.map((item, index) => (
                        <ProfileAboutCard key={index} title={item.title} legend={item.legend} footer={item.footer} image={item.image} />
                    ))}
            </Container>
        </SubSection>
    );
};

ProfileListAbout.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            legend: PropTypes.string.isRequired,
            footer: PropTypes.string,
            image: PropTypes.string
        })
    ).isRequired
};

export default ProfileListAbout;
