import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    > header {
        width: 100%;
        position: relative;

        > * {
            margin-bottom: 1rem;
        }

        > h1 {
            font-weight: 700;
            font-size: 24px;
            color: var(--color-black);
            padding-right: 1rem;
        }
    }

    > main {
        width: 100%;
        img {
            width: 100%;
            height: auto;
            border-radius: var(--border-radius);
        }
    }

    > footer {
        margin-top: 1rem;

        > * {
            margin-bottom: 0.8rem;
        }
    }
`;

export const AsideHeader = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

export const Description = styled.div`
    margin-top: 1rem;
    font-size: 0.9rem !important;
    line-height: 1.2rem !important;

    p {
        margin-top: 0.8rem;
    }

    font-size: 0.9rem !important;
    line-height: 1.2rem !important;
    color: #333 !important;
    background-color: transparent !important;
    font-family: 'Myriad Pro', sans-serif !important;
    font-style: italic;
    font-weight: 100;

    span {
        font-size: 0.9rem !important;
        color: #333 !important;
        font-family: 'Myriad Pro', sans-serif !important;
        background-color: transparent !important;
        line-height: 1.2rem !important;
        font-weight: 100;
    }
`;

export const FormatText = styled.p`
    font-size: 0.9rem !important;
    color: var(--color-gray);
    margin-top: 0.5rem;
`;
