import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Link } from 'react-router-dom';
import {
    ArticleOutlined,
    BackupTableOutlined,
    ImageOutlined,
    MenuBookOutlined,
    OndemandVideoOutlined,
    PictureAsPdfOutlined,
    StarOutline,
    VolumeUpOutlined
} from '@mui/icons-material';

const LibrarySearchCard = ({ type, item }) => {
    const getUri = () => {
        if (type === 'format') {
            return `/aluno/biblioteca/formato/${item.id}`;
        }

        if (type === 'competence') {
            return `/aluno/biblioteca/competencia/${item.id}`;
        }

        return '/aluno/biblioteca';
    };

    if (type === 'format') {
        return (
            <Link to={getUri()}>
                <Container color="#6B9D4A">
                    <span>{item.value}</span>
                    <aside>
                        {
                            {
                                1: <OndemandVideoOutlined fontSize="large" />,
                                2: <ArticleOutlined fontSize="large" />,
                                3: <ImageOutlined fontSize="large" />,
                                4: <MenuBookOutlined fontSize="large" />,
                                5: <StarOutline fontSize="large" />,
                                6: <VolumeUpOutlined fontSize="large" />,
                                7: <PictureAsPdfOutlined fontSize="large" />,
                                8: <BackupTableOutlined fontSize="large" />
                            }[item?.id]
                        }
                    </aside>
                </Container>
            </Link>
        );
    }

    return (
        <Link to={getUri()}>
            <Container color={item.color || '#6B9D4A'}>
                <span>{item.value}</span>
                <aside>
                    <img src={item.icon} alt={item.value} />
                </aside>
            </Container>
        </Link>
    );
};

LibrarySearchCard.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string,
        icon: PropTypes.string,
        color: PropTypes.string
    }).isRequired,
    type: PropTypes.string.isRequired
};

export default LibrarySearchCard;
