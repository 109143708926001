import PropTypes from 'prop-types';
import { SpeakerType } from '../speaker';

export const CourseType = {
    active: PropTypes.number,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    time: PropTypes.number,
    progress: PropTypes.number,
    registers: PropTypes.number,
    registered: PropTypes.bool,
    like: PropTypes.bool,
    likes: PropTypes.number,
    favorite: PropTypes.bool,
    favorited: PropTypes.bool,
    image: PropTypes.string,
    competences: PropTypes.arrayOf(PropTypes.object),
    speakers: PropTypes.arrayOf(PropTypes.shape(SpeakerType)),
    classes: PropTypes.arrayOf(PropTypes.object),
    finalDate: PropTypes.string
};

export const ClassType = {
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    id_course: PropTypes.number.isRequired,
    learningObject: PropTypes.arrayOf(PropTypes.object),
    myStudentComplete: PropTypes.bool,
    myStudentTimeStop: PropTypes.number,
    name: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    peso: PropTypes.number.isRequired,
    time: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired
};
