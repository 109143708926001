import { Chip } from '@mui/material';
import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled(Chip)`
    background-color: ${({ background }) => (background ? lighten(0.35, background) : 'var(--color-primary)')};
    color: ${({ background }) => (background ? background : '#fff')};
    border-radius: 16px;
    font-size: 0.7rem;
    margin-bottom: 2px;
`;
