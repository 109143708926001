import { CircularProgress } from '@mui/material';
import FilterTitle from 'components/molecules/headers/FilterTitle';
import LibraryList from 'components/organisms/lists/LibraryList';
import Meta from 'components/templates/Seo/Meta';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLibraryData, getLibraryParameters } from 'services/library';

const LibraryListScreen = () => {
    const { id, filter } = useParams();
    const dispatch = useDispatch();
    const [competences, setCompetences] = useState([]);
    const [formats, setFormats] = useState([]);
    const [courses, setCourses] = useState([]);
    const [learningObjects, setLearningObjects] = useState([]);

    const [loading, setLoading] = useState(true);
    const [filterParameters, setFilterParameters] = useState({});
    const [title, setTitle] = useState('');
    const snackbar = useSnackbar();

    const handleFilter = (filterParameter) => {
        setFilterParameters(filterParameter);
    };

    const handleTitle = () => {
        const getSelectItem = () => {
            if (filter === 'competencia') {
                return competences.find((competence) => competence.id === Number(id));
            }

            if (filter === 'formato') {
                return formats.find((format) => format.id === Number(id));
            }
        };

        const selectItem = getSelectItem();

        if (selectItem) {
            setTitle(selectItem.description);
        }
    };

    const getFilterStructure = () => {
        if (filter === 'competencia') {
            return [
                {
                    id: 'formats',
                    label: 'Tipo de arquivo',
                    type: 'options',
                    options: formats.map((item) => item.description)
                }
            ];
        }

        if (filter === 'formato') {
            return [
                {
                    id: 'competences',
                    label: 'Competências',
                    type: 'options',
                    options: competences?.map((item) => item.description)
                }
            ];
        }

        return [];
    };

    const orderTypeLearningObjects = (typeArrays) => {
        if (!typeArrays) {
            return [];
        }

        return typeArrays.sort((a) => {
            if (a.description === 'Cursos') return -3;
            if (a.description === 'Eventos') return -2;
            return 0;
        });
    };

    const getListData = async () => {
        await getLibraryData(filter, id)
            .then((response) => {
                setLearningObjects(response.data.learningObjects);
                setCourses(response.data.courses);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar biblioteca', 'error');
            });
    };

    const getListParametersData = async () => {
        await getLibraryParameters()
            .then((response) => {
                setCompetences(response.data.competences);
                setFormats(orderTypeLearningObjects(response.data.typeLearningObject));
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Erro ao carregar parâmetros', 'error');
            });
    };

    useEffect(() => {
        getListParametersData();
        getListData();
    }, []);

    useEffect(() => {
        if (competences?.length === 0 || formats?.length === 0) {
            return;
        }

        handleTitle();
    }, [competences, formats]);

    useEffect(() => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: '/aluno/biblioteca',
                returnText: 'Biblioteca',
                returnPath: location.pathname
            }
        });
    }, [id]);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            <Meta title={`${title} - Biblioteca `} />

            <FilterTitle
                title={title}
                filterStructure={getFilterStructure()}
                filterValue={filterParameters}
                setFilter={handleFilter}
                borderBottom
            />

            <LibraryList courses={courses} learningObjects={learningObjects} filterSearch={filterParameters} />
        </>
    );
};

export default LibraryListScreen;
