import styled from 'styled-components';

export const Container = styled.div`
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
    border-radius: 16px;

    > a {
        > header {
            padding: 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;

            > aside {
                margin-right: 1rem;
            }

            > main {
                > h3 {
                    font-size: 1rem;
                    color: var(--color-text);
                    margin-bottom: 0.4rem;
                }
            }
        }

        > main {
            padding: 1rem;
            padding-top: 0;
            width: 100%;

            > footer {
                margin-top: 0.5rem;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
            }
        }
    }

    > footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-top: 1px solid #e0e0e0;

        > aside {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            > p {
                font-size: 0.9rem;
                height: 100%;
                display: flex;
                align-items: center;
            }
        }

        > main {
            font-size: 0.9rem;
        }
    }
`;

export const ImageContainer = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    position: relative;
    margin-bottom: 1rem;

    > img {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-position: center;
        background-size: cover;
        object-fit: cover;
    }
`;

export const DescriptionText = styled.div`
    p {
        margin-top: 1rem;

        &:first-child {
            margin-top: 0;
        }
    }

    font-size: 0.9rem !important;
    line-height: 1.2rem !important;
    color: var(--color-black) !important;
    background-color: transparent !important;
    font-family: 'Myriad Pro', sans-serif !important;

    span {
        font-size: 0.9rem !important;
        color: var(--color-black) !important;
        font-family: 'Myriad Pro', sans-serif !important;
        background-color: transparent !important;
        line-height: 1.2rem !important;
    }
`;

export const ItemsContainer = styled.div`
    margin-bottom: 1rem;
`;
