import { CircularProgress } from '@mui/material';
import PostCard from 'components/molecules/cards/PostCard';
import SubSection from 'components/molecules/structure/SubSection';
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { TimelineItem } from 'types/data/timeline';
import TimelineCard from 'components/molecules/cards/TimelineCard';
import OpportunityCard from 'components/molecules/cards/OpportunityCard';

const Timeline = ({ posts, lastPage }) => {
    return (
        <SubSection title="Fique por dentro:" borderTop>
            <Container>
                {posts?.map((post, index) => {
                    if (post.typeText === 'Postagem') {
                        return <PostCard key={index} post={post} />;
                    }

                    if (post.typeText === 'Oportunidade') {
                        return <OpportunityCard key={index} post={post} />;
                    }

                    return <TimelineCard key={index} post={post} />;
                })}

                {!lastPage && (
                    <footer>
                        <CircularProgress />
                    </footer>
                )}
            </Container>
        </SubSection>
    );
};

Timeline.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.shape(TimelineItem)),
    lastPage: PropTypes.bool
};

export default Timeline;
