import { CircularProgress } from '@mui/material';
import Title from 'components/atoms/texts/Title';
import SearchHeader from 'components/molecules/headers/SearchHeader';
import Library from 'components/organisms/containers/Library';
import Meta from 'components/templates/Seo/Meta';
import useSnackbar from 'hooks/useSnackbar';
import React, { useEffect, useState } from 'react';
import { getLibraryParameters } from 'services/library';

const Biblioteca = () => {
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [competences, setCompetences] = useState([]);
    const [formats, setFormats] = useState([]);
    const snackbar = useSnackbar();

    const orderTypeLearningObjects = (typeArrays) => {
        if (!typeArrays) {
            return [];
        }

        return typeArrays.sort((a) => {
            if (a.description === 'Cursos') return -3;
            if (a.description === 'Eventos') return -2;
            return 0;
        });
    };

    const getListData = async () => {
        await getLibraryParameters()
            .then((response) => {
                setCompetences(response.data.competences);
                setFormats(orderTypeLearningObjects(response.data.typeLearningObject));
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Não foi possível carregar a listagens de conteúdo.', 'error');
            });
    };

    useEffect(() => {
        getListData();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            <Meta title="Biblioteca" />

            <Title title="Biblioteca" />

            <SearchHeader value={search} onChange={setSearch} />

            <Library search={search} competences={competences} formats={formats} />
        </>
    );
};

export default Biblioteca;
