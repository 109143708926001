import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import Button from 'components/molecules/buttons/Button';
import Chips from 'components/molecules/containers/Chips';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import { Container, ImageContainer, DescriptionText, ItemsContainer } from './styles';
import { TimelineItem } from 'types/data/timeline';
import { htmlClean } from 'utils/html';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import IconText from 'components/atoms/texts/IconText';
import { Business, LocationOn, Work } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { likePost } from 'services/timeline';
import useSnackbar from 'hooks/useSnackbar';
import { seePost } from 'services/post';

const OpportunityCard = ({ post }) => {
    const [liked, setLiked] = useState(post.liked);
    const [uri, setUri] = useState('');
    const navigate = useNavigate();
    const snackbar = useSnackbar();

    const handleLike = async (value) => {
        await likePost(post.id)
            .then(() => {
                setLiked(value);
                snackbar(value ? 'Postagem curtida com sucesso' : 'Postagem descurtida com sucesso', 'success');
            })
            .catch((err) => {
                snackbar(err.message || 'Erro ao curtir postagem', 'error');
            });

        setLiked(value);
    };

    const getUri = () => {
        const path = window?.location?.pathname;

        return `/aluno/publicacao/${post.id}${path ? `?returnUrl=${path}` : ''}`;
    };

    const redirect = async () => {
        await seePost(Number(post.id));
        const uri = getUri();

        navigate(uri);
    };

    useEffect(() => {
        setUri(getUri());
    }, [post]);

    return (
        <Container>
            <Link to={uri}>
                <header>
                    <aside>
                        <Avatar src="https://pfahomologacaoapi.rehagro.com.br/storage/images_system/rehagro.png" />
                    </aside>
                    <main>
                        <h3>{post.title}</h3>
                        <Chips
                            data={post.competences.map((item) => {
                                return { description: item.desription, color: item.color };
                            })}
                        />
                    </main>
                </header>
            </Link>
            <Link to={uri}>
                <main>
                    {post.image && (
                        <ImageContainer>
                            <img src={post.image} alt={post.title} />
                        </ImageContainer>
                    )}

                    {(post.local || post.period || post.company) && (
                        <ItemsContainer>
                            {post.local && <IconText icon={<LocationOn />} text={post.local} label="Local" />}
                            {post.period && <IconText icon={<Work />} text={post.period} label="Período" />}
                            {post.company && <IconText icon={<Business />} text={post.company} label="Empresa" />}
                        </ItemsContainer>
                    )}

                    <DescriptionText>{ReactHtmlParser(htmlClean(post.description).split('</p>')[0])}</DescriptionText>

                    <footer>
                        <Button size="fat" type="button" width="auto" color="neutral" onClick={redirect}>
                            Saiba mais
                        </Button>
                    </footer>
                </main>
            </Link>

            <footer>
                <aside>
                    <FavoriteCheckbox active={liked} setActive={handleLike} noPaddingLeft />
                    <p>{post.likes + liked - post.liked}</p>
                </aside>

                <main>
                    <p>{post.views} visualizações</p>
                </main>
            </footer>
        </Container>
    );
};

OpportunityCard.propTypes = {
    post: PropTypes.shape(TimelineItem)
};

export default OpportunityCard;
