import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const TextareaInput = ({ id, item, handleChange, value, handleBlur, error }) => {
    const { label, optional } = item;

    return (
        <Container
            label={`${label}${optional ? '' : '*'}`}
            value={value || ''}
            variant="outlined"
            onChange={(e) => handleChange(e)}
            helperText={error}
            error={!!error}
            onBlur={handleBlur}
            id={id}
            name={id}
            multiline
            rows={4}
        />
    );
};

TextareaInput.propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    value: PropTypes.any,
    error: PropTypes.string,
    id: PropTypes.string,
    item: PropTypes.object.isRequired
};

export default TextareaInput;
