import styled from 'styled-components';

export const Container = styled.div`
    border-radius: var(--border-radius);
    padding: 1rem;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: var(--box-shadow);
    display: flex;

    > aside {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
    }

    > main {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;

        > header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            width: 100%;
            margin-top: -0.8rem;
            margin-right: 1rem;
        }
    }
`;

export const IconContainer = styled.div`
    background-color: #e7f5e6;
    border-radius: 4px;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-secondary);
`;

export const Title = styled.a`
    font-size: 0.7rem;
    color: var(--color-text);
    font-weight: 500;
`;

export const Footer = styled.div`
    margin-top: -0.6rem;

    > h3 {
        width: 100%;
        color: var(--color-text);
        font-size: 0.9rem;
        line-height: 1.2rem;
        margin-bottom: 0.4rem;
    }
`;
