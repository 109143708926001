import PropTypes from 'prop-types';
import React from 'react';

import { Container, Content } from './styles';

const FullWidthContainer = ({ children, sticky, background, marginTop, padding }) => {
    return (
        <Container marginTop={marginTop} sticky={sticky} background={background} padding={padding}>
            <Content>{children}</Content>
        </Container>
    );
};

FullWidthContainer.propTypes = {
    children: PropTypes.node.isRequired,
    sticky: PropTypes.bool,
    background: PropTypes.string,
    marginTop: PropTypes.string,
    padding: PropTypes.string
};

export default FullWidthContainer;
