import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import PropTypes from 'prop-types';

import { Container } from './styles';

const PdfViewer = ({ file }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <Container>
                <Viewer fileUrl={file} plugins={[defaultLayoutPluginInstance]} />
            </Container>
        </Worker>
    );
};

PdfViewer.propTypes = {
    file: PropTypes.string.isRequired
};

export default PdfViewer;
