import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const BlankContainer = ({ children }) => {
    return <Container>{children}</Container>;
};

BlankContainer.propTypes = {
    children: PropTypes.node
};

export default BlankContainer;
