import TabsHeader from 'components/molecules/headers/TabsHeader';
import SubSection from 'components/molecules/structure/SubSection';
import PfaList from 'components/organisms/lists/PfaList';
import React, { useState } from 'react';

import { Container } from './styles';

const PfaContents = () => {
    const [activeTab, setActiveTab] = useState('events');

    const tabs = [
        {
            label: 'Eventos',
            value: 'events'
        },
        {
            label: 'Cursos',
            value: 'courses'
        }
    ];

    return (
        <SubSection title="Participação no PFA" borderTop>
            <Container>
                <TabsHeader tabs={tabs} setTab={setActiveTab} tab={activeTab} />

                {
                    {
                        events: (
                            <PfaList
                                data={[
                                    {
                                        label: 'Palestra:',
                                        title: 'Como acelerar seu crescimento Profissional',
                                        legend: 'Data: 13/04/2022',
                                        href: '/evento-1'
                                    },
                                    {
                                        label: 'Palestra:',
                                        title: 'Como acelerar seu crescimento Profissional',
                                        legend: 'Data: 13/04/2022',
                                        href: '/evento-1'
                                    },
                                    {
                                        label: 'Palestra:',
                                        title: 'Como acelerar seu crescimento Profissional',
                                        legend: 'Data: 13/04/2022',
                                        href: '/evento-1'
                                    }
                                ]}
                            />
                        ),
                        courses: (
                            <PfaList
                                data={[
                                    {
                                        label: 'Palestra:',
                                        title: 'Como acelerar seu crescimento Profissional',
                                        legend: 'Data: 13/04/2022',
                                        href: '/evento-1'
                                    },
                                    {
                                        label: 'Palestra:',
                                        title: 'Como acelerar seu crescimento Profissional',
                                        legend: 'Data: 13/04/2022',
                                        href: '/evento-1'
                                    },
                                    {
                                        label: 'Palestra:',
                                        title: 'Como acelerar seu crescimento Profissional',
                                        legend: 'Data: 13/04/2022',
                                        href: '/evento-1'
                                    }
                                ]}
                            />
                        )
                    }[activeTab]
                }
            </Container>
        </SubSection>
    );
};

export default PfaContents;
