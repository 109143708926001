import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const LikedContainer = ({ liked, setLiked, likes, text }) => {
    return (
        <Container>
            <h3>{text || 'Gostou? Nôs dê um like!'}</h3>

            <aside>
                <FavoriteCheckbox active={liked} setActive={setLiked} noPaddingLeft />
                <p>{likes + liked}</p>
            </aside>
        </Container>
    );
};

LikedContainer.propTypes = {
    liked: PropTypes.bool,
    setLiked: PropTypes.func,
    likes: PropTypes.number,
    text: PropTypes.string
};

export default LikedContainer;
