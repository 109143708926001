import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { CourseType } from 'types/data/course';
import { learningObjectType } from 'types/data/library';
import CourseCard from 'components/molecules/cards/CourseCard';
import LibraryObjectCard from 'components/molecules/cards/LibraryObjectCard';
import Message from 'components/molecules/alerts/Message';
import { CircularProgress } from '@mui/material';

const LibraryList = ({ courses, learningObjects, filterSearch, marginTop }) => {
    const [filteredCourses, setFilteredCourses] = useState(courses || []);
    const [filteredLearningObjects, setFilteredLearningObjects] = useState(learningObjects || []);
    const [loading, setLoading] = useState(true);

    const verifyFilterIsBlank = () =>
        (!filterSearch?.competences || filterSearch?.competences?.length === 0) &&
        (!filterSearch?.formats || filterSearch?.formats?.length === 0);

    const filterData = () => {
        if (verifyFilterIsBlank()) {
            setLoading(false);
            setFilteredCourses(courses);
            setFilteredLearningObjects(learningObjects);
            return;
        }

        if (filterSearch?.competences?.length > 0) {
            const filterCompetencesCourses = (course) =>
                filterSearch?.competences.filter((filterCompetence) =>
                    course.competences.some((eventCompetence) => eventCompetence.desription === filterCompetence)
                ).length > 0;

            const filterCompetencesLearningObjects = (learningObject) =>
                filterSearch?.competences.filter((filterCompetence) =>
                    learningObject.library.competence.some((competence) => competence.desription === filterCompetence)
                ).length > 0;

            setFilteredCourses(courses.filter(filterCompetencesCourses));
            setFilteredLearningObjects(learningObjects.filter(filterCompetencesLearningObjects));

            return;
        }

        if (filterSearch?.formats?.length > 0) {
            if (filterSearch?.formats.includes('Cursos')) {
                setFilteredCourses(courses);
            } else {
                setFilteredCourses([]);
            }

            setFilteredLearningObjects(
                learningObjects.filter((learningObject) => filterSearch?.formats.includes(learningObject.typeLearningObject))
            );

            return;
        }
    };

    useEffect(() => {
        filterData();
    }, [filterSearch, courses, learningObjects]);

    if (loading) {
        return <CircularProgress />;
    }

    if ((!filteredCourses && !filteredLearningObjects) || (filteredCourses?.length === 0 && filteredLearningObjects?.length === 0)) {
        return <Message text="Nenhum conteúdo encontrado." />;
    }

    return (
        <Container marginTop={marginTop}>
            {filteredLearningObjects.length > 0 || filteredCourses.length > 0 ? (
                <>
                    {filteredLearningObjects &&
                        filteredLearningObjects?.map((learningObject, index) => (
                            <LibraryObjectCard learningObject={learningObject} key={index} />
                        ))}

                    {filteredCourses &&
                        filteredCourses?.map((course) => <CourseCard key={course.id} course={course} pathName="Biblioteca" />)}
                </>
            ) : (
                <Message text={`Nenhum material encontrado ${!verifyFilterIsBlank() ? 'com o filtro inserido' : ''}`} />
            )}
        </Container>
    );
};

LibraryList.propTypes = {
    courses: PropTypes.arrayOf(PropTypes.shape(CourseType)),
    learningObjects: PropTypes.arrayOf(PropTypes.shape(learningObjectType)),
    filterSearch: PropTypes.object,
    marginTop: PropTypes.bool
};

export default LibraryList;
