import styled from 'styled-components';

export const Container = styled.div`
    > * {
        margin-top: 1rem;

        &:first-of-type {
            margin-top: 0.5rem;
        }
    }
`;
