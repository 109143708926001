import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Container, Item } from './styles';
import { IconButton } from '@mui/material';
import Button from '../../../molecules/buttons/Button';
import CloseIcon from '@mui/icons-material/Close';
import OptionsForm from '../../forms/Structure/OptionsForm';
import Backdrop from 'components/molecules/structure/Backdrop';

const Filter = ({ setFilter, structure, active, handler, data }) => {
    const [formData, setFormData] = useState({});

    const getFormItemStructure = (formItem) => {
        if (formItem.type === 'options') {
            return data[formItem?.id] || [];
        }

        return {};
    };

    const getStartData = () => {
        const formData = {};

        structure.forEach((formItem) => {
            formData[formItem.id] = getFormItemStructure(formItem);
        });

        setFormData(formData);
    };

    const changeFilterValue = useCallback(
        (values, id) => {
            setFormData((previousState) => {
                const newState = { ...previousState, [id]: values };
                return newState;
            });
        },
        [formData]
    );

    const clearForm = () => {
        setFormData({});
    };

    const submitForm = () => {
        setFilter(formData);
        handler();
    };

    useEffect(() => {
        getStartData();
    }, []);

    return (
        <>
            <Container active={active}>
                <header>
                    <h2>Filtrar Por</h2>
                    <IconButton onClick={() => handler()}>
                        <CloseIcon fontSize="large" color="primary" />
                    </IconButton>
                </header>

                <main>
                    {structure.map((item, index) => (
                        <div key={index}>
                            {
                                {
                                    options: (
                                        <Item key={index}>
                                            <OptionsForm
                                                title={item.label}
                                                options={item.options}
                                                values={formData[item.id] || []}
                                                setValues={(values) => changeFilterValue(values, item.id)}
                                            />
                                        </Item>
                                    )
                                }[item.type]
                            }
                        </div>
                    ))}
                </main>

                <footer>
                    <Button type="button" color="light" onClick={() => clearForm()}>
                        Limpar Filtro
                    </Button>
                    <Button type="button" color="secondary" onClick={() => submitForm()}>
                        Aplicar Filtros
                    </Button>
                </footer>
            </Container>
            <Backdrop active={active} handler={handler} />
        </>
    );
};

Filter.propTypes = {
    setFilter: PropTypes.func,
    structure: PropTypes.arrayOf(PropTypes.object),
    active: PropTypes.bool,
    handler: PropTypes.func,
    data: PropTypes.object
};

export default Filter;
