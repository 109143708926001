import MainNavbar from '../../../organisms/layout/navbars/MainNavbar';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Content } from './styles';
import BottomNavbar from 'components/organisms/layout/navbars/BottomNavbar';
import { logged } from 'services/auth';
import { useDispatch } from 'react-redux';
import SubNavbar from 'components/organisms/layout/navbars/SubNavbar';
import useQueryParam from 'hooks/useQueryParam';
import { loadTotalNotifications } from 'redux/reducers/notificationsReducer';
import Snackbar from 'components/molecules/alerts/Snackbar';

const DashboardLayout = ({ hiddenBottomNavbar }) => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const router = useSelector((state) => state.router);
    const [subNavbarData, setSubNavbarData] = useState(null);
    const query = useQueryParam();

    const checkAuth = async () => {
        if (auth?.user?.id) {
            return;
        }

        const loggedData = await logged();

        if (!loggedData) {
            navigate('/login');
            return;
        }

        dispatch({
            type: 'SET_AUTH',
            payload: {
                email: loggedData.email,
                name: loggedData.name,
                id: loggedData.id,
                studentId: loggedData.id_student
            }
        });
    };

    const getReturnUrl = () => {
        const returnUrl = query.get('returnUrl');

        if (returnUrl) {
            return returnUrl;
        }

        return null;
    };

    const getReturnName = () => {
        const returnName = query.get('returnName');

        if (returnName) {
            return returnName;
        }

        return null;
    };

    const checkRouterSidebar = () => {
        if (!router?.returnUrl || !router?.returnText || !router?.returnUrl) {
            setSubNavbarData(null);
            return;
        }

        if (router.returnPath !== location.pathname) {
            setSubNavbarData(null);
            return;
        }

        const returnUrl = getReturnUrl() || router.returnUrl;

        setSubNavbarData({
            title: getReturnName() || router.returnText,
            url: returnUrl
        });
    };

    useEffect(() => {
        checkAuth();
    }, []);

    useEffect(() => {
        loadTotalNotifications(dispatch);
    }, []);

    useEffect(() => {
        checkRouterSidebar();
    }, [router, window.location.pathname]);

    return (
        <Container>
            {subNavbarData ? <SubNavbar title={subNavbarData.title} url={subNavbarData.url} /> : <MainNavbar />}
            <Content hiddenBottomNavbar={hiddenBottomNavbar}>
                <Outlet />
                <Snackbar />
            </Content>
            {hiddenBottomNavbar ? null : <BottomNavbar />}
        </Container>
    );
};

DashboardLayout.propTypes = {
    hiddenBottomNavbar: PropTypes.bool
};

export default DashboardLayout;
