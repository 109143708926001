import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import StarIcon from '@mui/icons-material/Star';

const Stars = ({ data }) => {
    const { stars, color } = data;

    return (
        <Container color={color} complete={stars === 5}>
            {Array(stars)
                .fill(0)
                .map((_, index) => (
                    <StarIcon key={index} fontSize={'large'} />
                ))}
        </Container>
    );
};

Stars.propTypes = {
    data: PropTypes.shape({
        stars: PropTypes.number.isRequired,
        color: PropTypes.string.isRequired
    }).isRequired
};

export default Stars;
