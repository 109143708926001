import styled from 'styled-components';

export const Container = styled.div`
    height: 200px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0;
    border: 1px solid #e6e6e6;
    cursor: pointer;

    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    > p {
        width: calc(100% - 60px);
        height: calc(100% - 60px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #333;

        > svg {
            color: #e6e6e6;
            width: 35px;
            height: 35px;
            margin-bottom: 8px;
        }
    }
`;
