import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import FullWidthContainer from '../../../atoms/structure/FullWidthContainer';
import CarouselButton from 'components/molecules/buttons/CarouselButton';

const ListCarousel = ({ data, selectedData, onItemClick, selectableItems, description, reference }) => {
    const handleItemClick = (value, scroll) => {
        if (scroll) {
            const component = document.querySelector(`#${reference}-${value}`);

            if (!component) {
                return;
            }

            const y = component.getBoundingClientRect().top + window.scrollY - 150;
            window.scrollTo(0, y);
        }

        onItemClick(value);
    };

    return (
        <FullWidthContainer sticky background="var(--color-background)" marginTop={description ? null : '0.1rem'}>
            <Container paddingTop={!description}>
                {description && (
                    <header>
                        <h4>{description}</h4>
                    </header>
                )}
                <main>
                    {data.map((item) => (
                        <CarouselButton
                            key={item.id}
                            value={item.id}
                            text={item.text}
                            active={selectedData === item.id}
                            selectable={selectableItems.includes(item.id)}
                            onClick={(scroll) => handleItemClick(item.id, scroll)}
                        />
                    ))}
                </main>
            </Container>
        </FullWidthContainer>
    );
};

ListCarousel.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            text: PropTypes.string
        })
    ).isRequired,
    selectedData: PropTypes.number.isRequired,
    onItemClick: PropTypes.func.isRequired,
    selectableItems: PropTypes.arrayOf(PropTypes.number).isRequired,
    reference: PropTypes.string.isRequired,
    description: PropTypes.string
};

export default ListCarousel;
