import styled from 'styled-components';

export const Container = styled.div`
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: var(--box-shadow);
    border-radius: 8px;

    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;

    > header {
        display: grid;
        grid-template-columns: 1fr 2fr;

        img {
            flex: 1;
            height: 100%;
            background-color: #ddd;
            border-radius: var(--border-radius);
            width: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;
            height: auto;
        }
    }
`;

export const BodyCard = styled.div`
    > main {
        padding: 1rem 0;
        font-size: 0.8rem !important;
        line-height: 1.2rem !important;
        color: #333 !important;
        background-color: transparent !important;
        font-family: 'Myriad Pro', sans-serif !important;

        span {
            font-size: 0.8rem !important;
            color: #333 !important;
            font-family: 'Myriad Pro', sans-serif !important;
            background-color: transparent !important;
            line-height: 1.2rem !important;
        }
    }

    > footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > p {
            font-weight: 700;
            font-size: 0.9rem;
            color: #333;
        }
    }
`;

export const BlankImage = styled.div`
    flex: 1;
    width: 100%;
    aspect-ratio: 1/1;
    background-color: #ddd;
    border-radius: var(--border-radius);
`;

export const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
    justify-content: space-between;

    main {
        h2 {
            font-size: 0.9rem;
            color: var(--color-text);
        }

        p {
            margin-top: 0.5rem;
            font-size: 0.7rem;
            font-weight: 700;
            color: var(--color-text);
        }
    }
`;

export const ActionsArea = styled.div`
    margin-top: -0.5rem;
    margin-right: -0.5rem;
`;
