import InlineStudents from 'components/molecules/containers/InlineStudents';
import UserInfoHeader from 'components/molecules/headers/UserInfoHeader';
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { userType } from 'types/data/user';

const UserInfo = ({ user, totalStudents, users }) => {
    return (
        <Container>
            <UserInfoHeader user={user} />
            {users.length > 0 && <InlineStudents users={users} total={totalStudents} />}
        </Container>
    );
};

UserInfo.propTypes = {
    user: PropTypes.shape(userType).isRequired,
    users: PropTypes.arrayOf(PropTypes.shape(userType)).isRequired,
    totalStudents: PropTypes.number.isRequired
};

export default UserInfo;
