import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Container, EventColumn } from './styles';
import Message from 'components/molecules/alerts/Message';
import { months } from 'constants/date';
import EventCard from 'components/molecules/cards/EventCard';

const EventsList = ({ events, onlySubscribe, isFiltered }) => {
    const [eventsByMonth, setEventsByMonth] = useState([]);
    const [message, setMessage] = useState('Nenhum evento encontrado');

    const getEventsByMonth = () => {
        const eventsByMonth = [];
        let eventMonth;

        events.forEach((event) => {
            eventMonth = new Date(event.initialDate).getMonth();

            if (!eventsByMonth[eventMonth]) {
                eventsByMonth[eventMonth] = [];
            }

            eventsByMonth[eventMonth] = [...eventsByMonth[eventMonth], event];
        });

        setEventsByMonth(eventsByMonth);
    };

    useEffect(() => {
        getEventsByMonth();
    }, [events]);

    useEffect(() => {
        if (onlySubscribe) {
            setMessage(`Você não está inscrito em nenhum evento ${isFiltered ? 'com o filtro selecionado' : 'até agora'} `);
            return;
        }

        if (isFiltered) {
            setMessage('Nenhum evento encontrado com o filtro selecionado');
            return;
        }

        setMessage('Nenhum evento encontrado');
    }, [onlySubscribe, isFiltered]);

    return (
        <Container>
            {events.length === 0 ? (
                <Message text={message} />
            ) : (
                Object.keys(eventsByMonth).map((month) => {
                    return (
                        <EventColumn key={month} id={`month-${month}`}>
                            <header>
                                <h2>{months[month]}</h2>
                            </header>

                            <main>
                                {eventsByMonth[month].map((event) => (
                                    <EventCard key={event.id} event={event} />
                                ))}
                            </main>
                        </EventColumn>
                    );
                })
            )}
        </Container>
    );
};

EventsList.propTypes = {
    events: PropTypes.arrayOf(PropTypes.object).isRequired,
    onlySubscribe: PropTypes.bool,
    isFiltered: PropTypes.bool
};

export default EventsList;
