import Cookies from 'js-cookie';
import { removeCookie } from 'utils/cookies';
import { addHours } from '../../utils/date';
import { api } from '../api';

const logged = async () => {
    const token = Cookies.get('token');

    if (!token) {
        return false;
    }

    const response = await api
        .post('me')
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return JSON.parse(error.request.response);
        });

    return response.data;
};

const authentication = async (email, password) => {
    const response = await api
        .post('login', {
            email: email,
            password: password
        })
        .then((data) => {
            removeCookie('token');

            Cookies.set('token', data.data.access_token, {
                expires: addHours(new Date(), 2)
            });
            return data;
        })
        .catch((error) => {
            return JSON.parse(error.request.response);
        });

    return response;
};

const recoverPassword = async (email) => {
    const response = await api
        .post('password/email', {
            email
        })
        .catch((error) => {
            return { message: JSON.parse(error.request.response).error };
        });

    return response;
};

const resetPassword = async (email, password, passwordConfirmation, token) => {
    const response = await api
        .post('password/reset', {
            email,
            password,
            password_confirmation: passwordConfirmation,
            token
        })
        .catch((error) => {
            return JSON.parse(error.request.response);
        });

    return response;
};

const logout = () => {
    removeCookie('token');
};

export { authentication, recoverPassword, resetPassword, logged, logout };
