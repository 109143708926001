import { CircularProgress } from '@mui/material';
import Message from 'components/molecules/alerts/Message';
import ProfileRedirect from 'components/molecules/cards/ProfileRedirect';
import LevelCarousel from 'components/molecules/carousels/LevelCarousel';
import ProfileAbout from 'components/organisms/containers/ProfileAbout';
import UserDescription from 'components/organisms/containers/UserDescription';
import ProfileListAbout from 'components/organisms/lists/ProfileListAbout';
import PfaContents from 'components/organisms/tabsSections/PfaContents';
import Meta from 'components/templates/Seo/Meta';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getProfileById } from 'services/profile';
import { getStudentLevel } from 'services/level';
import useSnackbar from 'hooks/useSnackbar';

const Perfil = () => {
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [profile, setProfile] = useState({});
    const auth = useSelector((state) => state.auth);
    const [error, setError] = useState('');

    const snackbar = useSnackbar();
    const [levels, setLevels] = useState([]);
    const [currentLevel, setCurrentLevel] = useState(null);

    const getProfile = async () => {
        if (!auth?.user || profile?.id) {
            return;
        }

        const data = await getProfileById(auth.user.studentId).then((response) => response);

        if (data.status !== 200) {
            setError(data.data.error);
            setLoading(false);
        }

        if (data.status === 401) {
            navigate('/login');
            return;
        }

        setProfile(...data.data);
        setLoading(false);
    };

    const getCurrentLevel = async () => {
        const getProgress = (level, myLevel) => {
            if (!myLevel) {
                return 0;
            }

            return myLevel?.id >= level.id ? 100 : level.id === myLevel.nextLevel.id ? myLevel.nextLevel.nextPercent : 0;
        };

        await getStudentLevel()
            .then((res) => {
                const levels = res.data.levelsExperiences.map((level) => {
                    return {
                        id: level.id,
                        level: level.level,
                        name: level.name,
                        progress: getProgress(level, res.data.levelExperience)
                    };
                });

                setLevels(levels);
                setCurrentLevel(res.data.levelExperience.id);
            })
            .catch((error) => {
                snackbar(error, 'error');
                setLoading(false);
            });
    };

    useEffect(() => {
        getCurrentLevel();
    }, []);

    useEffect(() => {
        if (auth?.user.studentId) {
            getProfile(auth?.user.studentId);
        }
    }, [auth?.user.studentId, auth]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error || !profile?.id) {
        return <Message text={error} />;
    }

    return (
        <>
            <Meta title="Perfil" />

            <UserDescription name={profile.name} city={profile.city} occupation={profile.occupation} state={profile.state} />

            <LevelCarousel data={levels} currentLevel={currentLevel} />

            <ProfileRedirect
                imageUrl={'https://pfahomologacaoapi.rehagro.com.br/storage/images_competences/Group_349.png'}
                href="/aluno/perfil/competencias"
                title="Veja suas Competências"
                description="Evolua o seu nível no PFA através do desenvolvimento de suas competências."
            />

            <ProfileAbout text={profile.description} />

            {profile.academicEducation !== undefined && profile.academicEducation.length > 0 && (
                <ProfileListAbout title="Formação Acadêmica" data={profile.academicEducation} />
            )}

            {profile.courseAndCertificate !== undefined && profile.courseAndCertificate.length > 0 && (
                <ProfileListAbout title="Cursos e certificados" data={profile.courseAndCertificate} />
            )}

            {profile.language !== undefined && profile.language.length > 0 && <ProfileListAbout title="Idiomas" data={profile.language} />}

            {profile.experience !== undefined && profile.experience.length > 0 && (
                <ProfileListAbout title="Experiências" data={profile.experience} />
            )}

            <PfaContents />
        </>
    );
};

export default Perfil;
