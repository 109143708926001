import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Description, Title, BlankImage, DescriptionText } from './styles';
import FavoriteCheckbox from 'components/atoms/inputs/FavoriteCheckbox';
import Button from 'components/molecules/buttons/Button';
import SpeakerItem from 'components/molecules/headers/SpeakerItem';
import CheckListItem from 'components/molecules/listItems/CheckListItem';
import Dialog from 'components/molecules/alerts/Dialog';
import { useSelector } from 'react-redux';
import SaveCheckbox from 'components/atoms/inputs/SaveCheckbox';
import Line from 'components/atoms/structure/Line';
import TimerDuration from 'components/atoms/texts/TimerDuration';
import { useNavigate } from 'react-router-dom';
import { CourseType } from 'types/data/course';
import { favoriteCourse, likeCourse, subscribeCourse } from 'services/courses';
import ReactHtmlParser from 'react-html-parser';
import { htmlClean } from 'utils/html';
import useSnackbar from 'hooks/useSnackbar';

const Course = ({ course }) => {
    const { id, image, speakers, isFavorite, liked, competences, time, description, registered, title } = course;

    const [isFavorited, setIsFavorited] = useState(isFavorite);
    const [isLiked, setIsLiked] = useState(liked);
    const [isRegistered, setIsRegistered] = useState(registered);
    const [onLoad, setOnLoad] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const snackbar = useSnackbar();

    const handleFavorite = async (value) => {
        await favoriteCourse(id, auth?.user?.studentId, value)
            .then(() => {
                setIsFavorited(value);
                snackbar(value ? 'Curso favoritado com sucesso' : 'Curso removido dos favoritos com sucesso', 'success');
            })
            .catch((err) => {
                snackbar(err.message || 'Erro ao favoritar curso', 'error');
            });
    };

    const handleLike = async (value) => {
        await likeCourse(id, auth?.user?.studentId, value)
            .then(() => {
                setIsLiked(value);
                snackbar(value ? 'Curso curtido com sucesso' : 'Curso descurtido com sucesso', 'success');
            })
            .catch((err) => {
                snackbar(err.message || 'Erro ao curtir curso', 'error');
            });
    };

    const handleRegister = async () => {
        if (isRegistered) {
            return;
        }

        setOnLoad(true);

        await subscribeCourse(id, auth?.user?.studentId, !isRegistered)
            .then(() => {
                setIsRegistered(!isRegistered);
                snackbar('Inscrição realizada com sucesso!', 'success');
            })
            .catch((err) => {
                snackbar(err.error || 'Erro ao inscrever-se no curso', 'error');
            })
            .finally(() => {
                setOnLoad(false);
            });
    };

    const redirectToWatch = () => {
        navigate(`/aluno/cursos/assistir/${id}`);
    };

    return (
        <Container>
            <main>
                <header>{image ? <img src={image} alt={title} /> : <BlankImage />}</header>
                <Title>
                    <h1>{title}</h1>
                    <aside>
                        <FavoriteCheckbox active={isLiked} setActive={handleLike} />
                        <SaveCheckbox active={isFavorited} setActive={handleFavorite} />
                    </aside>
                </Title>

                <Description>
                    <div>
                        <h3>Professor</h3>
                        {speakers.map((speaker, index) => (
                            <SpeakerItem key={index} speaker={speaker} />
                        ))}
                    </div>

                    <DescriptionText>
                        <h3>Descrição</h3>
                        {ReactHtmlParser(htmlClean(description))}
                    </DescriptionText>

                    <TimerDuration minutes={time} onlyHours />

                    <Line />

                    <div>
                        <h3>Evolua suas competências</h3>
                        {competences.map((item, index) => (
                            <CheckListItem text={item.desription} key={index} />
                        ))}
                    </div>
                </Description>
            </main>

            <footer>
                <Button
                    disabled={onLoad}
                    onClick={() => {
                        isRegistered ? redirectToWatch() : setDialogOpen(true);
                    }}
                    type="button"
                >
                    {isRegistered ? 'Assistir' : 'Inscreva-se!'}
                </Button>
            </footer>

            <Dialog
                open={dialogOpen}
                submit={() => handleRegister()}
                text="Deseja se inscrever no curso?"
                handler={() => setDialogOpen(false)}
                submitText="Inscrever-se"
                cancelText="Voltar"
            />
        </Container>
    );
};

Course.propTypes = {
    course: PropTypes.shape(CourseType).isRequired
};

export default Course;
