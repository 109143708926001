import { api } from 'services/api';

const getStudentLevel = async () => {
    const response = await api
        .get(`/student_level_experience`)
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: response.data,
        status: response.status
    };
};

export { getStudentLevel };
