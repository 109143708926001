import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from 'services/auth';

const SidebarItem = ({ data, handler }) => {
    const { title, icon, disabled, link, activeIcon } = data;
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const redirect = async () => {
        if (disabled || link === location.pathname) {
            return;
        }

        handler();

        if (link === '/logout') {
            logout();

            await dispatch({ type: 'SET_LOGOUT' });
            navigate('/');

            return;
        }

        navigate(link);
    };

    return (
        <Container onClick={() => redirect()} active={link === location.pathname}>
            <aside>{link === location.pathname ? activeIcon : icon}</aside>
            <main>
                <p>{title}</p>
            </main>
        </Container>
    );
};

SidebarItem.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        icon: PropTypes.element,
        activeIcon: PropTypes.element,
        disabled: PropTypes.bool,
        link: PropTypes.string
    }).isRequired,
    handler: PropTypes.func.isRequired
};

export default SidebarItem;
