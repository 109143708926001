import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    ${({ marginTop }) => marginTop && `margin-top: 1rem;`}

    > * {
        margin-top: 1rem;

        &:first-of-type {
            margin-top: 0;
        }
    }
`;
