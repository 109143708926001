import SubSection from 'components/molecules/structure/SubSection';
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Footer } from './styles';
import FullWidthContainer from 'components/atoms/structure/FullWidthContainer';
import ParticipatingCourseCard from 'components/molecules/cards/ParticipatingCourseCard';
import FinishedCourseCard from 'components/molecules/cards/FinishedCourseCard';
import { useNavigate } from 'react-router-dom';
import Message from 'components/molecules/alerts/Message';

const CoursesCarousel = ({ courses, removeCompletedCourse, finishedCourses }) => {
    const navigate = useNavigate();
    const title = finishedCourses ? 'Cursos que você já concluiu:' : 'Cursos que você está participando:';

    const redirectToAll = () => {
        navigate('/aluno/cursos/concluidos');
    };

    return (
        <FullWidthContainer>
            <SubSection title={title} paddingLeft borderTop>
                <Container>
                    {courses.length === 0 ? (
                        <Message
                            html
                            text={
                                finishedCourses
                                    ? 'Você ainda não concluiu nenhum curso.'
                                    : 'Você ainda não iniciou nenhum curso.<br/>Explore as competências acima.'
                            }
                        />
                    ) : (
                        <>
                            {finishedCourses
                                ? courses.map((course, index) => <FinishedCourseCard course={course} key={index} />)
                                : courses.map((course, index) => (
                                      <ParticipatingCourseCard course={course} key={index} removeCompletedCourse={removeCompletedCourse} />
                                  ))}
                        </>
                    )}
                </Container>
                {finishedCourses && courses.length > 0 && (
                    <Footer>
                        <button onClick={() => redirectToAll()}>Ver tudo</button>
                    </Footer>
                )}
            </SubSection>
        </FullWidthContainer>
    );
};

CoursesCarousel.propTypes = {
    courses: PropTypes.arrayOf(PropTypes.object).isRequired,
    removeCompletedCourse: PropTypes.func.isRequired,
    finishedCourses: PropTypes.bool
};

export default CoursesCarousel;
