import TabsHeader from '../../../components/molecules/headers/TabsHeader';
import React, { useEffect, useState } from 'react';
import FilterTitle from '../../../components/molecules/headers/FilterTitle';
import { getNextMonths } from '../../../utils/date';
import { getEvents, getFilterData } from 'services/events';
import { CircularProgress } from '@mui/material';
import EventsList from 'components/organisms/lists/EventsList';
import Meta from 'components/templates/Seo/Meta';
import { useSelector } from 'react-redux';
import ListCarousel from 'components/molecules/carousels/ListCarousel';
import { verifyEndOfPage } from 'utils/scroll';
import useSnackbar from 'hooks/useSnackbar';

const Eventos = () => {
    const [months, setMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(0);
    const [tab, setTab] = useState('Todos de 2022');
    const [filter, setFilter] = useState({});

    const [events, setEvents] = useState([]);
    const [showedEvents, setShowedEvents] = useState([]);

    const [filterData, setFilterData] = useState({});
    const [selectableMonths, setSelectableMonths] = useState([]);
    const [scrolling, setScrolling] = useState(false);
    const [higherYItem, setHigherYItem] = useState(0);
    const [scrollY, setScrollY] = useState(0);
    const auth = useSelector((state) => state.auth);
    const snackbar = useSnackbar();

    const [loading, setLoading] = useState(true);

    const filterStructure = [
        {
            id: 'eventTypes',
            label: 'Tipo do Evento',
            type: 'options',
            options: filterData?.eventTypes || []
        },
        {
            id: 'competences',
            label: 'Competências',
            type: 'options',
            options: filterData?.competences || []
        }
    ];

    const tabs = [
        {
            value: 'Todos de 2022',
            label: 'Todos de 2022'
        },
        {
            value: 'Inscritos',
            label: 'Inscritos'
        }
    ];

    const getMonthsData = () => {
        const monthToday = new Date().getMonth();

        const monthsData = getNextMonths(monthToday).map((month, index) => {
            return {
                id: index + monthToday < 12 ? monthToday + index : index + monthToday - 12,
                text: month
            };
        });

        setSelectedMonth(monthToday);
        setMonths(monthsData);

        console.log(monthsData);
    };

    const getEventsData = async () => {
        if (!auth?.user?.studentId) {
            return;
        }

        await getEvents(auth.user.studentId)
            .then((response) => {
                setEvents(response.data);
                setLoading(false);

                if (response.data.some((event) => event.registered)) {
                    setTab('Inscritos');
                }
            })
            .catch((error) => {
                console.error(error);
                snackbar('Não foi possível carregar os eventos.', 'error');
            });
    };

    const getFilterOptions = async () => {
        await getFilterData()
            .then((response) => {
                setFilterData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                snackbar('Não foi possível carregar os filtros.', 'error');
            });
    };

    const getSelectableMonths = () => {
        const eventsByMonth = [];

        showedEvents.forEach((event) => {
            const eventMonth = new Date(event.initialDate).getMonth();

            if (eventsByMonth.includes(eventMonth)) {
                return;
            }

            eventsByMonth.push(eventMonth);
        });

        setSelectableMonths(eventsByMonth);
    };

    const filterEvents = () => {
        const subscribeValidation = (event) => {
            if (tab === 'Inscritos') {
                return event.registered === true;
            }

            return true;
        };

        const filterEventTypesValidation = (event) => {
            if (filter?.eventTypes && filter.eventTypes.length > 0) {
                return filter.eventTypes.includes(event.typeEvent);
            }

            return true;
        };

        const filterCompetencesValidation = (event) => {
            if (filter?.competences && filter.competences.length > 0) {
                return (
                    filter.competences.filter((filterCompetence) =>
                        event.competences.some((eventCompetence) => eventCompetence.desription === filterCompetence)
                    ).length > 0
                );
            }

            return true;
        };

        const eventsFiltered = events.filter(
            (event) => subscribeValidation(event) && filterEventTypesValidation(event) && filterCompetencesValidation(event)
        );

        setShowedEvents(eventsFiltered);
    };

    const verifyScroll = () => {
        if (scrolling) {
            return;
        }

        let offsetY = 0;
        let component = null;

        console.log({
            selectedMonth,
            months: selectableMonths.map((month) => months[month]),
            selectableMonths
        });

        if (scrollY !== 0) {
            if (verifyEndOfPage(scrollY)) {
                setSelectedMonth(selectableMonths[selectableMonths.length - 1]);
                return;
            }
        }

        selectableMonths.forEach((monthIndex) => {
            component = document.getElementById(`month-${monthIndex}`);

            if (!component) {
                return;
            }

            offsetY = component.getBoundingClientRect().top;

            if (offsetY < 150 && offsetY > 0) {
                setSelectedMonth(monthIndex);
                return;
            }

            if (scrollY < higherYItem && selectableMonths.length > 0) {
                setSelectedMonth(selectableMonths[0]);
            }
        });
    };

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        setScrollY(currentScrollY);
    };

    const getHigherYItem = () => {
        const item = document.querySelector(`#month-${selectableMonths[0]}`);

        if (!item) {
            return;
        }

        setHigherYItem(item.getBoundingClientRect().top);
    };

    const verifySelectedMonth = () => {
        if (selectableMonths.length === 0) {
            setSelectedMonth(-1);
            return;
        }

        getHigherYItem();

        if (selectableMonths.includes(selectedMonth)) {
            return;
        }

        setSelectedMonth(selectableMonths[0]);
    };

    const handleMonthChange = (index) => {
        setScrolling(true);
        setSelectedMonth(index);

        setTimeout(() => {
            setScrolling(false);
        }, 500);
    };

    useEffect(() => {
        getMonthsData();
        getEventsData();
        getFilterOptions();
    }, [auth]);

    useEffect(() => {
        filterEvents();
    }, [events, tab, filter]);

    useEffect(() => {
        getSelectableMonths();
    }, [showedEvents]);

    useEffect(() => {
        verifySelectedMonth();
    }, [selectableMonths]);

    useEffect(() => {
        verifyScroll();
    }, [scrollY]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <Meta title="Eventos" description="Visualize os eventos que acontecerão." />

            <FilterTitle title="Eventos" filterStructure={filterStructure} filterValue={filter} setFilter={setFilter} />

            <TabsHeader tab={tab} setTab={setTab} tabs={tabs} />

            <ListCarousel
                data={months}
                selectedData={selectedMonth}
                onItemClick={handleMonthChange}
                selectableItems={selectableMonths}
                reference="month"
            />

            {loading || events === undefined ? (
                <CircularProgress />
            ) : (
                <EventsList
                    events={showedEvents}
                    isFiltered={!!(filter?.eventTypes || filter?.competences)}
                    onlySubscribe={tab === 'Inscritos'}
                />
            )}
        </>
    );
};

export default Eventos;
