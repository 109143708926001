import { CircularProgress } from '@mui/material';
import Message from 'components/molecules/alerts/Message';
import WatchHeader from 'components/molecules/headers/WatchHeader';
import WatchClass from 'components/organisms/containers/WatchClass';
import Meta from 'components/templates/Seo/Meta';
import useQueryParam from 'hooks/useQueryParam';
import useSnackbar from 'hooks/useSnackbar';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { favoriteCourse, getCourseById, likeCourse } from 'services/courses';
import { finishClass } from 'services/courses/watch';

const Watch = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [course, setCourse] = useState({});
    const [isliked, setIsLiked] = useState(false);
    const [likes, setLikes] = useState(0);
    const [isFavorite, setIsFavorite] = useState(false);
    const [progress, setProgress] = useState(0);
    const [activeClass, setActiveClass] = useState({});
    const [maxClass, setMaxClass] = useState(0);
    const [lastLesson, setLastLesson] = useState(false);
    const [finished, setFinished] = useState(false);

    const dispatch = useDispatch();
    const location = useLocation();
    const auth = useSelector((state) => state.auth);
    const query = useQueryParam();
    const snackbar = useSnackbar();

    const handleFavorite = async (value) => {
        await favoriteCourse(id, auth?.user?.studentId, value)
            .then(() => {
                setIsFavorite(value);
                snackbar(value ? 'Curso favoritado com sucesso' : 'Curso desfavoritado com sucesso', 'success');
            })
            .catch((err) => {
                snackbar(err.error, 'error');
            });
    };

    const handleLike = async (value) => {
        await likeCourse(id, auth?.user?.studentId, value)
            .then(() => {
                setIsLiked(value);
                snackbar(value ? 'Curso curtido com sucesso' : 'Curso descurtido com sucesso', 'success');
            })
            .catch((err) => {
                snackbar(err.error, 'error');
            });
    };

    const changeClass = async (value) => {
        const selectedClass = course.classes.find((classItem) => classItem.number === value.toString());
        setActiveClass(selectedClass);
    };

    const getCourseData = useCallback(async () => {
        await getCourseById(id)
            .then((response) => {
                setCourse(response.data);
            })
            .catch((error) => {
                setError(error.data.error);

                console.error(error);
                snackbar('Não foi possível carregar os cursos.', 'error');
            });
    }, []);

    const fillData = () => {
        const getActualClassData = () => {
            if (course.classes?.length === 0) {
                setError('Não existem aulas para este curso.');
                return null;
            }

            const currentClass = course.classes.find((classData) => classData.myStudentComplete === false);

            if (!currentClass) {
                return course.classes[0];
            }

            return currentClass;
        };

        if (!course?.id) {
            return;
        }

        const actualClass = getActualClassData();

        setIsFavorite(course.isFavorite);
        setIsLiked(course.liked);
        setLikes(course.likes - course.liked);
        setActiveClass(actualClass);
        setMaxClass(course.completeMyStudent === 100 ? course.classes.length : Number(actualClass?.number) || 0);
        setProgress(course.progress || 56);
        setLoading(false);
    };

    const nextClass = () => {
        if (Number(activeClass.number) === course.classes.length) {
            return;
        }

        const nextClass = course.classes.find((classData) => classData.number === (Number(activeClass.number) + 1).toString());

        if (Number(activeClass.number) + 1 > maxClass) {
            setMaxClass(Number(activeClass.number) + 1);
        }
        window.scrollTo(0, 0);
        setActiveClass(nextClass);
    };

    const calculateProgress = () => {
        if (!course?.classes) {
            return;
        }

        let completedClasses = 0;
        let totalClasses = 0;

        course.classes.forEach((classData) => {
            if (classData.myStudentComplete) {
                completedClasses++;
            }

            totalClasses++;
        });

        setProgress(Math.round((completedClasses / totalClasses) * 100));
    };

    const handleFinish = () => {
        if (!activeClass.myStudentComplete) {
            finishClass(activeClass.id, auth?.user?.studentId);
        }

        const finishedClassIndex = course.classes.findIndex((classData) => classData.id === activeClass.id);

        if (finishedClassIndex + 1 === course.classes.length) {
            snackbar('Curso concluído com sucesso', 'success');
        } else {
            snackbar('Aula concluída com sucesso', 'success');
        }

        setFinished(true);

        setCourse((prevCourse) => ({
            ...prevCourse,
            classes: prevCourse.classes.map((classData, index) => {
                if (index === finishedClassIndex) {
                    return {
                        ...classData,
                        myStudentComplete: true
                    };
                }

                return classData;
            })
        }));
    };

    const getReturnUrl = () => {
        const returnUrl = query.get('returnUrl');

        if (returnUrl) {
            return returnUrl;
        }

        return '/aluno/cursos';
    };

    const checkFinishedClass = () => {
        if (activeClass.myStudentComplete) {
            return true;
        }

        const video = activeClass.learningObject.find((learningObject) => learningObject.typeLearningObject === 'Videos');

        if (!video) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        if (loading) {
            fillData();
        }

        calculateProgress();
    }, [course]);

    useEffect(() => {
        dispatch({
            type: 'SET_RETURN',
            payload: {
                returnUrl: getReturnUrl(),
                returnText: 'Cursos',
                returnPath: location.pathname
            }
        });
    }, []);

    useEffect(() => {
        getCourseData();
    }, []);

    useEffect(() => {
        if (course?.classes?.length === 0 || !activeClass?.id) {
            return;
        }

        setLastLesson(course?.classes && Number(activeClass.number) === course?.classes?.length);

        if (checkFinishedClass()) {
            handleFinish();
        } else {
            setFinished(false);
        }
    }, [activeClass]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error || !course?.id || !course.classes) {
        return <Message text={error || ' Não foi possível carregar os dados do curso.'} />;
    }

    return (
        <>
            <Meta title={`Assistir ${course?.title}`} />

            <WatchHeader
                title={course?.title}
                classes={course?.classes}
                favorite={isFavorite}
                handleFavorite={handleFavorite}
                progress={progress}
                id={course.id}
                activeClassNumber={Number(activeClass.number)}
                maxClassNumber={maxClass}
                changeClass={changeClass}
                finished={finished}
            />

            <WatchClass
                watchClass={activeClass}
                liked={isliked}
                setLiked={handleLike}
                likes={likes}
                changeClass={changeClass}
                nextClass={nextClass}
                handleFinish={handleFinish}
                lastLesson={lastLesson}
                finished={finished}
                setFinished={setFinished}
            />
        </>
    );
};

export default Watch;
