import PropTypes from 'prop-types';
import React from 'react';

import { Container } from './styles';

const Title = ({ heading, title }) => {
    return (
        <Container>
            {heading && <p>{heading}</p>}
            <h1>{title}</h1>
        </Container>
    );
};

Title.propTypes = {
    heading: PropTypes.string,
    title: PropTypes.string.isRequired
};

export default Title;
