import { lighten } from 'polished';
import styled from 'styled-components';
import { getTypeColor } from 'utils/rules/competence';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: var(--border-radius);
    background-color: ${({ color }) => (color ? lighten(0.35, color) : lighten(0.35, '#6B9D4A'))};
    color: var(--color-text);
    position: relative;

    max-width: 100%;
    height: 3.5rem;
    padding: 0.5rem;

    > span {
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        z-index: 2;
        color: var(--color-black);
        margin-right: 1rem;
    }

    > aside {
        position: absolute;
        z-index: 1;

        right: 0.5rem;
        filter: ${({ color }) =>
            getTypeColor(color) === 'orange'
                ? 'invert(70%) sepia(32%) saturate(1035%) hue-rotate(346deg) brightness(92%) contrast(89%)'
                : getTypeColor(color) === 'purple'
                ? 'invert(44%) sepia(30%) saturate(1012%) hue-rotate(203deg) brightness(89%) contrast(87%)'
                : 'invert(58%) sepia(32%) saturate(656%) hue-rotate(53deg) brightness(91%) contrast(87%)'};
    }
`;
