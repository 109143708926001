import axios from 'axios';
import Cookies from 'js-cookie';

const BASE_URL = 'https://pfahomologacaoapi.rehagro.com.br/api/';

const api = axios.create({
    baseURL: BASE_URL
});

api.interceptors.request.use(
    (config) => {
        return {
            ...config,
            headers: {
                ...config.headers,
                common: {
                    ...config.headers.common,
                    Authorization: `Bearer ${Cookies.get('token')}`
                }
            }
        };
    },
    (error) => Promise.reject(error)
);

export { api };
