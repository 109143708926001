import PropTypes from 'prop-types';
import { CompetenceType } from '../competence';

export const TimelineItem = {
    id: PropTypes.number,
    liked: PropTypes.bool,
    likes: PropTypes.number,
    views: PropTypes.number,
    viewed: PropTypes.bool,
    type: PropTypes.number,
    typeText: PropTypes.string,
    typeAdvertisement: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    competences: PropTypes.arrayOf(PropTypes.shape(CompetenceType)),
    company: PropTypes.string,
    salary: PropTypes.number,
    period: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.object),
    image: PropTypes.string
};
