import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { LevelType } from 'types/data/level';
import Progress from 'components/atoms/inputs/Progress';
import { getStarsByLevel } from './utils';
import Stars from 'components/atoms/structure/Stars';

const LevelCard = ({ currentLevel, level }) => {
    const isCurrentLevel = currentLevel === level.id;
    const componentId = `level-card-${level.id}`;

    return (
        <Container id={componentId}>
            <header>
                <Stars data={getStarsByLevel(level.id)} />
            </header>

            <main>
                <span>{isCurrentLevel && 'Nível Atual'}</span>
                <h2>{level.name}</h2>
            </main>

            <footer>
                <Progress progress={level.progress} color="secondary" />
            </footer>
        </Container>
    );
};

LevelCard.propTypes = {
    currentLevel: PropTypes.number.isRequired,
    level: PropTypes.shape(LevelType).isRequired
};

export default LevelCard;
