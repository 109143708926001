import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { pfaListItemType } from 'types/components/lists/PfaListItem';
import PfaListCard from 'components/molecules/cards/PfaListCard';

const PfaList = ({ data }) => {
    return (
        <Container>
            {data.map((item, index) => (
                <PfaListCard key={index} {...item} />
            ))}
        </Container>
    );
};

PfaList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape(pfaListItemType)).isRequired
};

export default PfaList;
