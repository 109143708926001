import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    > h3 {
        font-size: 1rem;
    }

    > aside {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 0rem;

        > p {
            margin-top: -0.2rem;
            font-size: 1rem;
            margin-left: 0.3rem;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
`;
