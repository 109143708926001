import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    padding: calc(var(--padding-base) / 2);
    grid-template-columns: 1fr 5fr;
    width: 100%;
    position: relative;
    margin-top: 0.5rem;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    gap: 0.5rem;
    margin-top: 1rem;

    > aside {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: flex-start;
    }

    > main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        width: 100%;

        > header {
            width: 100%;
            margin-bottom: 0.5rem;

            h2 {
                font-size: 0.9rem;
                color: var(--color-black);
                padding-bottom: 0.5rem;
            }
        }

        > footer {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            > button {
                margin-left: 0.5rem;
            }
        }
    }
`;
