import { Edit } from '@mui/icons-material';
import FullWidthContainer from 'components/atoms/structure/FullWidthContainer';
import React, { useEffect, useState } from 'react';
import EditProfile from 'components/organisms/menus/EditProfile';
import PropTypes from 'prop-types';

import { ContainerHeader, EditProfileButton, ImageContainer, ContainerFooter } from './styles';
import useQueryParam from 'hooks/useQueryParam';

const UserDescription = ({ name, city, occupation, state }) => {
    const [editProfile, setEditProfile] = useState(false);
    const query = useQueryParam();

    useEffect(() => {
        const editProfile = query.get('editProfile');

        if (editProfile) {
            setEditProfile(true);
        }
    }, [query]);
    return (
        <>
            <FullWidthContainer marginTop="0">
                <ContainerHeader>
                    <ImageContainer />
                    <EditProfileButton onClick={() => setEditProfile(true)}>
                        <Edit />
                    </EditProfileButton>
                </ContainerHeader>
            </FullWidthContainer>

            <ContainerFooter>
                <h2>{name}</h2>
                <h3>{occupation || ''}</h3>
                <p>
                    {city || ''} , {state}
                </p>
            </ContainerFooter>

            <EditProfile active={editProfile} handler={() => setEditProfile(false)} />
        </>
    );
};

UserDescription.propTypes = {
    name: PropTypes.string.isRequired,
    occupation: PropTypes.string,
    city: PropTypes.string
};

export default UserDescription;
