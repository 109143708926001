import React from 'react';
import { Container, Mark } from './styles';
import TimerText from '../../../atoms/texts/TimerText';
import Button from 'components/molecules/buttons/Button';
import { useNavigate } from 'react-router-dom';
import DateVertical from 'components/atoms/texts/DateVertical';
import { Beenhere } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { EventType } from 'types/data/event';
import Chips from 'components/molecules/containers/Chips';
import NowText from 'components/atoms/texts/NowText';

const EventCard = ({ event }) => {
    const { id, title, initialDate, finalDate, competences, registered, typeEvent } = event;
    const navigate = useNavigate();

    const redirectToEvent = () => {
        navigate(`/aluno/eventos/${id}`);
    };

    const now = () => {
        const now = new Date();

        return now.getTime() > new Date(initialDate).getTime() && now.getTime() < new Date(finalDate).getTime();
    };

    return (
        <Container>
            {registered && (
                <Mark>
                    <Beenhere color="secondary" fontSize="small" />
                </Mark>
            )}
            <aside>
                <DateVertical dateString={initialDate.toString()} />
            </aside>
            <main>
                <header>
                    <h2>
                        {typeEvent}: {title}
                    </h2>

                    <Chips
                        data={competences.map((competence) => {
                            return { description: competence.desription, color: competence.color };
                        })}
                    />
                </header>
                <footer>
                    {now() ? <NowText /> : <TimerText initialDate={initialDate.toString()} finalDate={finalDate.toString()} />}

                    <Button size="fat" type="button" onClick={() => redirectToEvent()} width="auto" color="neutral">
                        Saiba mais
                    </Button>
                </footer>
            </main>
        </Container>
    );
};

EventCard.propTypes = {
    event: PropTypes.shape(EventType).isRequired
};

export default EventCard;
