import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Line = ({ color }) => {
    return <Container color={color} />;
};

Line.propTypes = {
    color: PropTypes.string
};

export default Line;
