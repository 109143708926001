import { months } from '../../constants/date';
import moment from 'moment';

const addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    return date;
};

const addHours = (date, hours) => {
    const dateHour = new Date(date);
    dateHour.setHours(dateHour.getHours() + hours);

    return dateHour;
};

const getNextMonths = (yearIndex) => {
    const nextMonths = [...months.slice(yearIndex), ...months.slice(0, yearIndex)];
    return nextMonths;
};

const dateJsToISO = (date) => {
    return moment(date).toISOString();
};

export { addDays, addHours, getNextMonths, dateJsToISO };
