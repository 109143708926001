import styled from 'styled-components';

export const Container = styled.div`
    .MuiSvgIcon-root {
        ${(props) => props.fontSize && `font-size: ${props.fontSize}rem;`}
        ${(props) => props.margin && `margin: ${props.margin};`}
        margin-top: -0.2rem;
        color: #333;
    }
`;
