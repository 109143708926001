const getStarsByLevel = (level) => {
    switch (level) {
        case 1:
            return {
                stars: 1,
                color: '#A9B2B1'
            };
        case 2:
            return {
                stars: 2,
                color: '#A9B2B1'
            };
        case 3:
            return {
                stars: 3,
                color: '#A9B2B1'
            };
        case 4:
            return {
                stars: 4,
                color: '#A9B2B1'
            };
        case 5:
            return {
                stars: 5,
                color: '#A9B2B1'
            };
        default:
            return 0;
    }
};

export { getStarsByLevel };
