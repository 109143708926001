import { api } from 'services/api';

const getPostById = async (id) => {
    const response = await api
        .get(`/publication/${id}`)
        .then((res) => res)
        .catch((err) => err.response);

    if (response.status === 401) {
        window.location.href = '/login';
        throw new Error('Token Expirado');
    }

    if (response.status !== 200) {
        throw new Error(response.data.error);
    }

    return {
        data: parsePost(response.data),
        status: response.status
    };
};

const seePost = async (idPublication) => {
    const response = await api
        .post(`/saw_publication`, {
            idPublication
        })
        .then((res) => res)
        .catch((err) => err.response);

    return {
        data: response.data,
        status: response.status
    };
};

const parsePost = (data) => {
    return {
        id: data?.id,
        title: `Oportunidade: ${data?.office}`,
        company: data?.company,
        liked: data?.liked,
        likes: data?.likes,
        views: data?.saw,
        salary: data?.salary,
        period: data?.period_opportunity,
        local: data?.local,
        description: data?.description,
        image: data?.file ? (data?.file[0]?.url ? getImageUrl(data?.file[0].url) : '') : '',
        url: data?.url
    };
};

export { getPostById, seePost };
