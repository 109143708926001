import React from 'react';

import { Container } from './styles';
import PropTypes from 'prop-types';
import Backdrop from '../../../../molecules/structure/Backdrop';
import UserSidebar from 'components/molecules/headers/UserSidebar';
import { sidebarData } from 'constants/components/sidebar';
import SidebarItem from 'components/molecules/listItems/SidebarItem';
import { useSelector } from 'react-redux';

const MainSidebar = ({ active, handler }) => {
    const auth = useSelector((state) => state.auth);
    const { user } = auth;

    return (
        <>
            <Container active={active}>
                <header>
                    <h2>Profissional do Futuro do Agronegócio</h2>
                    {user?.id && <UserSidebar name={user.name} email={user.email} />}
                </header>
                <main>
                    {sidebarData.map((item, index) => (
                        <SidebarItem key={index} data={item} handler={handler} />
                    ))}
                </main>
            </Container>

            <Backdrop active={active} handler={handler} />
        </>
    );
};

MainSidebar.propTypes = {
    active: PropTypes.bool,
    handler: PropTypes.func
};

export default MainSidebar;
