import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, CloseContainer } from './styles';
import Progress from 'components/atoms/inputs/Progress';
import Button from 'components/molecules/buttons/Button';
import { Link, useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';

const ParticipatingCourseCard = ({ course, removeCompletedCourse }) => {
    const { id, title, progress, image } = course;
    const navigate = useNavigate();
    const [removed, setRemoved] = useState(false);
    const path = window.location.pathname;
    const completed = progress === 100;
    const uri = `/aluno/cursos/assistir/${id}${path ? `?returnUrl=${path}` : ''}`;

    const removeCourse = () => {
        removeCompletedCourse(id);
        setRemoved(true);
    };

    const redirectToWatch = () => {
        if (removed) {
            return;
        }

        navigate(uri);
    };

    return (
        <Container completed={completed}>
            <Link to={uri}>
                <aside>{image ? <img src={image} alt={title} /> : <img src="https://via.placeholder.com/126" alt={title} />}</aside>
            </Link>
            <main>
                <header>
                    <h3>{title}</h3>
                    {completed && (
                        <CloseContainer onClick={() => removeCourse()} aria-label="close" size="small">
                            <Close size="small" />
                        </CloseContainer>
                    )}
                </header>
                <footer>
                    <Progress progress={progress} />
                    <Button size="small" type="button" width="auto" onClick={() => redirectToWatch()} color="neutral">
                        Continuar
                    </Button>
                </footer>
            </main>
        </Container>
    );
};

ParticipatingCourseCard.propTypes = {
    course: PropTypes.object.isRequired,
    removeCompletedCourse: PropTypes.func.isRequired
};

export default ParticipatingCourseCard;
