import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import React from 'react';

import { Container } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { bottomNavbarData } from 'constants/components/bottomNavbar';

const BottomNavbar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const redirect = (path) => {
        navigate(path);
    };

    return (
        <Container>
            <BottomNavigation
                showLabels
                value={location.pathname}
                onChange={(event, newValue) => {
                    redirect(newValue);
                }}
            >
                {bottomNavbarData.map((item, index) => (
                    <BottomNavigationAction key={index} disabled={item.disabled} label={item.title} value={item.link} icon={item.icon} />
                ))}
            </BottomNavigation>
        </Container>
    );
};

export default BottomNavbar;
